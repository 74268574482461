import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ApiUrl from "../Utils/ApiUrl";
import AllBlogList from "../Components/AllBlogList";
import Footer from "../Components/Layout/Footer";
import Header from "../Components/Layout/Header";


function SingleBlog(props) {
  const { id } = useParams();
  const [blog, setBlog] = useState("");
  const getBlogDetails = () => {
    console.log(`${ApiUrl.Blog}/${id}`);
    axios.get(`${ApiUrl.Blog}/${id}`).then((res) => {
      console.log(res.data.data[0]);
      setBlog(res.data.data[0]);
    });
  };
  useEffect(() => {
    getBlogDetails();
    const script = document.createElement("script");
    script.src = "js/singleblog.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Header/>
      <section class="cum-sociis">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8">
              <div class="cum-box">
                <div class="cum-img">
                  <img
                    src={`${ApiUrl.getImage}bannerImage-1682562764111-838170977.png`}
                    alt=""
                  />
                </div>
                <h2>{blog.title}</h2>
                <ul>
                  <li>
                    <div class="media align-items-center">
                      <img class="mr-3" src="img/user-image-03.png" alt="" />
                      {/* <div class="media-body">{blog.author_info[0].name}</div> */}
                    </div>
                  </li>
                  <li>
                    <i class="ti-calendar"></i> <span>{blog.createdAt}</span>
                  </li>
                  <li>
                    <i class="ti-book"></i>{" "}
                    <span>{blog.readingTime} Min Read</span>
                  </li>
                  <li>
                    <i class="ti-heart"></i> <span>Like</span>
                  </li>
                  <li>
                    <i class="ti-sharethis"></i> <span>Share</span>
                  </li>
                </ul>
                <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                <div class="cum-img">
                  <img src="img/main-image-01.png" alt="" />
                </div>
                <p>
                  If you tried any of these chat bots, you know how easily you
                  can ask questions and get responses in a wide range of
                  formats.
                </p>
                <div class="b-border"></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="user-box">
                <div class="user-img">
                  <img src={`${ApiUrl.getImage}1682590405223.png`} alt="" />
                </div>
                <h4>{blog.name}</h4>
                <ul>
                  <li>
                    <Link to={blog.instagramUrl} target="blank">
                      <i class="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={blog.youtubeUrl} target="blank">
                      <i class="ti-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={blog.linkedinUrl} target="blank">
                      <i class="ti-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={blog.facebookUrl} target="blank">
                      <i class="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={`mailto:${blog.email}`}>
                      <i class="ti-email"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="blog-categ">
                <h3>CATEGORIES</h3>
                <ul>
                  <li class="active">
                    <span>
                      <img src="img/fitness-icon.png" alt="" />
                    </span>{" "}
                    fitness
                  </li>
                  <li>
                    <span>
                      <img src="img/la_life-ring.png" alt="" />
                    </span>{" "}
                    Lifestyle
                  </li>
                  <li>
                    <span>
                      <img src="img/nutrition.png" alt="" />
                    </span>{" "}
                    Nutrition
                  </li>
                  <li>
                    <span>
                      <img src="img/health.png" alt="" />
                    </span>{" "}
                    Health
                  </li>
                  <li>
                    <span>
                      <img src="img/yoga.png" alt="" />
                    </span>{" "}
                    Health
                  </li>
                </ul>
              </div>
              <div class="news-release">
                <h3>News Release Articles</h3>
                <div class="release-box mb-4">
                  <div class="release-left">
                    <div class="media align-items-center mb-2">
                      <img class="mr-3" src="img/user-image.png" alt="" />
                      <div class="media-body">Sarah Atkins In Yoga</div>
                    </div>
                    <p>
                      Not Every Day Is Good. But There Is Something Good In
                      Everyday!
                    </p>
                  </div>
                  <div class="release-img">
                    <img src="img/user-image-right-01.png" alt="" />
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="release-box mb-4">
                  <div class="release-left">
                    <div class="media align-items-center mb-2">
                      <img class="mr-3" src="img/user-image-01.png" alt="" />
                      <div class="media-body">William Mcdonals</div>
                    </div>
                    <p>The Benfits Of Morning, Lunchtime And Night-Time</p>
                  </div>
                  <div class="release-img">
                    <img src="img/user-image-right-02.png" alt="" />
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="release-box mb-4">
                  <div class="release-left">
                    <div class="media align-items-center mb-2">
                      <img class="mr-3" src="img/user-image-02.png" alt="" />
                      <div class="media-body">Evelyn Powel In Cycling</div>
                    </div>
                    <p>
                      Not Every Day Is Good. But There Is Something Good In
                      Everyday!
                    </p>
                  </div>
                  <div class="release-img">
                    <img src="img/user-image-right-03.png" alt="" />
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="release-box mb-4">
                  <div class="release-left">
                    <div class="media align-items-center mb-2">
                      <img class="mr-3" src="img/user-image.png" alt="" />
                      <div class="media-body">Sarah Atkins In Yoga</div>
                    </div>
                    <p>
                      Not Every Day Is Good. But There Is Something Good In
                      Everyday!
                    </p>
                  </div>
                  <div class="release-img">
                    <img src="img/user-image-right-01.png" alt="" />
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="release-box mb-4">
                  <div class="release-left">
                    <div class="media align-items-center mb-2">
                      <img class="mr-3" src="img/user-image-01.png" alt="" />
                      <div class="media-body">William Mcdonals</div>
                    </div>
                    <p>The Benfits Of Morning, Lunchtime And Night-Time</p>
                  </div>
                  <div class="release-img">
                    <img src="img/user-image-right-02.png" alt="" />
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="release-box mb-4">
                  <div class="release-left">
                    <div class="media align-items-center mb-2">
                      <img class="mr-3" src="img/user-image-02.png" alt="" />
                      <div class="media-body">Evelyn Powel In Cycling</div>
                    </div>
                    <p>
                      Not Every Day Is Good. But There Is Something Good In
                      Everyday!
                    </p>
                  </div>
                  <div class="release-img">
                    <img src="img/user-image-right-03.png" alt="" />
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="latest-news blog-news">
        <div class="container-fluid">
          <div class="heading" data-aos="zoom-out" data-aos-duration="1000">
            <span>All Articles</span>
            <h2>fitness</h2>
          </div>
          <div class="row blog-slider">
            <AllBlogList/>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default SingleBlog;
