import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Layout/Footer";
import WorkoutList from "../Components/Workout/WorkoutList";
import Header from "../Components/Layout/Header";

function Workout() {
  console.log('inside workout component')
  const[level , setLevel] = useState("Popular")
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "js/workout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <section class="inner-banner position-relative">
        <Header/>
        <div class="workout-banner">
          <div class="container-fluid">
            <div class="row d-flex align-items-center">
              <div class="col-md-6">
                <div class="work-content">
                  <div>
                    <h2>Be A Part Of Growing Fitness Community</h2>
                    <a href="#">JOIN NOW</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="slider slider-for">
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner.png" alt="" />
                      <div class="play-button">
                        <img src="img/play-button.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner.png" alt="" />
                      <div class="play-button">
                        <img src="img/play-button.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner.png" alt="" />
                      <div class="play-button">
                        <img src="img/play-button.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner.png" alt="" />
                      <div class="play-button">
                        <img src="img/play-button.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 px-0 banner-bottom">
                <div class="slider slider-nav">
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner2.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner3.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner4.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div class="work-silder">
                      <img src="img/work-banner5.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="blog-sec work-out">
        <div class="container-fluid">
          <div class="heading" data-aos="zoom-out">
            <span>Work Out</span>
            <h2>Work Out Sessions</h2>
          </div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <Link
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={()=>setLevel("Popular")}
              >
                Most Popular Workouts
              </Link>
            </li>
            <li class="nav-item" role="presentation">
              <Link
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={()=> setLevel("Latest")}
              >
                Latest Workouts
              </Link>
            </li>
            <li class="nav-item" role="presentation">
              <Link
                class="nav-link mr-0"
                id="messages-tab"
                data-toggle="tab"
                href="#messages"
                role="tab"
                aria-controls="messages"
                aria-selected="false"
                onClick={()=> setLevel("")}
              >
                All Workouts
              </Link>
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="all-projects">
                <div className="row portfolio-slider">
                  <WorkoutList tag = {level}/>
                </div>
              </div>
            </div>
            <div
              class="tab-pane"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="all-projects">
                <div class="row portfolio-slider">
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Total Body</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img2.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Cardio</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img3.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Crossfit</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Total Body</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img2.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Cardio</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img3.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Crossfit</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Lifestyle</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane"
              id="messages"
              role="tabpanel"
              aria-labelledby="messages-tab"
            >
              <div class="all-projects">
                <div class="row portfolio-slider">
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Total Body</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img2.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Cardio</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img3.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Crossfit</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Total Body</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img2.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Cardio</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Beginner</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="portfolio-box wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <a href="">
                        <div class="portfolio-img">
                          <div class="overlay"></div>
                          <img src="img/workout-img3.png" alt="" />
                        </div>
                        <div class="portfolio-content">
                          <h3 class="h3-title">Crossfit</h3>
                          <p>
                            It is a long established fact that a reader a page
                            when looking at ists layout
                          </p>
                        </div>
                      </a>
                      <div class="work-bottom d-flex justify-content-between align-center">
                        <div class="work-left">Lifestyle</div>
                        <div class="work-right">
                          <ul>
                            <li>
                              <img src="img/mdi_stopwatch-stop.png" alt="" /> 20
                              MIN
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="our-coache">
        <div class="container-fluid">
          <div class="heading" data-aos="zoom-out">
            <span>Our Coaches</span>
            <h2>Know Your Coaches</h2>
          </div>
          <div class="all-projects">
            <div class="row portfolio-slider2">
              <div class="col-lg-4">
                <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
                  <a href="">
                    <div class="portfolio-img">
                      <div class="overlay"></div>
                      <img src="img/portfolio-img2.png" alt="" />
                    </div>
                    <div class="portfolio-content">
                      <h3 class="h3-title">Fitness Trainer</h3>
                    </div>
                  </a>
                  <div class="work-bottom d-flex justify-content-between align-center">
                    <div class="work-left">Advanced</div>
                    <div class="work-right">
                      <ul>
                        <li>
                          Lv.4 <img src="img/level-intermediate.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
                  <a href="">
                    <div class="portfolio-img">
                      <div class="overlay"></div>
                      <img src="img/portfolio-img4.png" alt="" />
                    </div>
                    <div class="portfolio-content">
                      <h3 class="h3-title">Yoga</h3>
                    </div>
                  </a>
                  <div class="work-bottom d-flex justify-content-between align-center">
                    <div class="work-left">Intermediate</div>
                    <div class="work-right">
                      <ul>
                        <li>
                          Lv.1 <img src="img/level-intermediate.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
                  <a href="">
                    <div class="portfolio-img">
                      <div class="overlay"></div>
                      <img src="img/portfolio-img2.png" alt="" />
                    </div>
                    <div class="portfolio-content">
                      <h3 class="h3-title">Cycling</h3>
                    </div>
                  </a>
                  <div class="work-bottom d-flex justify-content-between align-center">
                    <div class="work-left">Beginner</div>
                    <div class="work-right">
                      <ul>
                        <li>
                          Lv.3 <img src="img/level-intermediate.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
                  <a href="">
                    <div class="portfolio-img">
                      <div class="overlay"></div>
                      <img src="img/portfolio-img4.png" alt="" />
                    </div>
                    <div class="portfolio-content">
                      <h3 class="h3-title">Running</h3>
                    </div>
                  </a>
                  <div class="work-bottom d-flex justify-content-between align-center">
                    <div class="work-left">Experienced</div>
                    <div class="work-right">
                      <ul>
                        <li>
                          Lv.2 <img src="img/level-intermediate.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
                  <a href="">
                    <div class="portfolio-img">
                      <div class="overlay"></div>
                      <img src="img/portfolio-img2.png" alt="" />
                    </div>
                    <div class="portfolio-content">
                      <h3 class="h3-title">Cardio</h3>
                    </div>
                  </a>
                  <div class="work-bottom d-flex justify-content-between align-center">
                    <div class="work-left">Beginner</div>
                    <div class="work-right">
                      <ul>
                        <li>
                          Lv.2 <img src="img/level-intermediate.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
                  <a href="">
                    <div class="portfolio-img">
                      <div class="overlay"></div>
                      <img src="img/portfolio-img4.png" alt="" />
                    </div>
                    <div class="portfolio-content">
                      <h3 class="h3-title">Crossfit</h3>
                    </div>
                  </a>
                  <div class="work-bottom d-flex justify-content-between align-center">
                    <div class="work-left">Lifestyle</div>
                    <div class="work-right">
                      <ul>
                        <li>
                          Lv.6 <img src="img/level-intermediate.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="our-community">
        <div class="circle-shape">
          <img src="img/circle-shap.png" alt="" />
        </div>
        <div class="circle-shape circle-shape2">
          <img src="img/circle-shap.png" alt="" />
        </div>
        <div class="circle-shape circle-shape3">
          <img src="img/circle-shap.png" alt="" />
        </div>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div
              class="col-md-5"
              data-aos="fade-up-right"
              data-aos-duration="1000"
            >
              <h2>Join Our Best community</h2>
              <p>
                We are here for help you! To Shape Your Body. when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book.
              </p>
              <h3>Ask your questions</h3>
              <ul>
                <li>
                  <a href="#" class="btn2">
                    JOIN US
                  </a>
                </li>
                <li>
                  <a href="#" class="btn2">
                    CONTACT US
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-7">
              <div
                class="mobile-hand"
                data-aos="fade-up-left"
                data-aos-duration="1000"
              >
                <div class="galaxy">
                  <div class="phone">
                    <div class="phone__hardware">
                      <div class="phone__hardware__speaker"></div>
                      <div class="phone__hardware__camera"></div>
                      <div class="phone__hardware__camera phone__hardware__camera--sensor"></div>
                      <div class="phone__hardware__camera phone__hardware__camera--sensor2"></div>
                    </div>
                    <div class="phone__controls">
                      <div class="phone__controls__volume"></div>
                      <div class="phone__controls__power"></div>
                      <div class="phone__controls__annoying-button-nobody-likes"></div>
                    </div>
                    <div class="phone__screen">
                      <video
                        id=""
                        class="video-js"
                        controls
                        preload="auto"
                        poster="img/mob_appcov.png"
                        data-setup="{}"
                      >
                        <source src="video/port_video.mp4" type="video/mp4" />
                        <p class="vjs-no-js">
                          To view this video please enable JavaScript, and
                          consider upgrading to a web browser that
                          <a
                            href="https://videojs.com/html5-video-support/"
                            target="_blank"
                          >
                            supports HTML5 video
                          </a>
                        </p>
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Workout;
