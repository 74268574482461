import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Layout/Header";
import WorkoutCategoryList from "../Components/Workout/WorkoutCategoryList";
import Footer from "../Components/Layout/Footer";
import ApiUrl from "../Utils/ApiUrl";
import CategoryWiseWorkoutList from "../Components/Workout/CategoryWiseWorkoutList";

function WorkoutDetail() {
  const location = useLocation();
  const data = location.state?.data;
  console.log("data value in workoutdetail");
  const [category, setCategory] = useState(data);
  console.log(data);
  useEffect(() => {
    console.log("inside workoutdetail useeffect ");
    console.log(`${ApiUrl.workout}?category=${category}`);
    // axios
    //   .get(`${ApiUrl.workout}?category=${category}`)
    //   .then((res) => {
    //     console.log("category Data");
    //     console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
    console.log("inside workout detail useEffect");
    const script = document.createElement("script");
    script.src = "js/workoutDetail.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [category]);
  console.log(category);
  return (
    <>
      <section class="inner-banner work-detail-banner position-relative ">
        <Header />
      </section>
      <section class="blog-sec work-details">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <WorkoutCategoryList category={category} setCategory={setCategory} />
        </ul>
        <div class="tab-content">
          <div
            class="tab-pane active"
            id="cardiovascular"
            role="tabpanel"
            aria-labelledby="cardiovascular-tab"
          >
            <div class="cardio-vid">
              <div class="container-fluid">
                <div class="row">
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <div class="video-wrap">
                      <iframe
                        frameborder="0"
                        allowfullscreen="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        title="15 MIN HIIT Full Body Workout – Intermediate Level"
                        width="100%"
                        height="250px"
                        src="https://www.youtube.com/embed/ViqOmHQf6q0?enablejsapi=1&amp;origin=https%3A%2F%2Fwww.fittr.com&amp;widgetid=1"
                        id="widget2"
                        data-gtm-yt-inspected-9="true"
                      ></iframe>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-left"
                    data-aos-duration="1000"
                  >
                    <div class="work-content">
                      <h2>Cardio &amp; Abs Home Workout</h2>
                      <div class="focus-area short-description mb-4">
                        <h3>Description</h3>
                        <p>
                          It is a long Established fact that a reader a page
                          when looking at its layout.It is a long Established
                          fact that a reader a page when looking at its
                          layout.It is a long Established fact that a reader a
                          page when looking at its layout.
                        </p>
                      </div>
                      <div class="focus-area mb-4">
                        <h3>Focus Areas</h3>
                        <ul class="mb-4">
                          <li>Flexibility</li>
                          <li>Stress Reduction</li>
                        </ul>
                        <ul>
                          <li>Warm Up</li>
                          <li>Mental &amp; Emotional Well Being</li>
                        </ul>
                      </div>
                      <div class="focus-area workout-details mb-4">
                        <h3>Workout Details</h3>
                        <ul>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="icon">
                                <img src="img/level-intermediate.png" alt="" />
                              </div>
                              <h4>Intensity</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/fluent-mdl2_calories.png"
                                  alt=""
                                />
                                <div class="media-body">250 Kcal</div>
                              </div>
                              <h4>CALORIES</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/mdi_stopwatch-stop.png"
                                  alt=""
                                />
                                <div class="media-body">20 Min</div>
                              </div>
                              <h4>Time</h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="barContainer">
                        <div class="mb-5">
                          <h3>insights</h3>
                          <div class="bar">
                            <div class="light-red"></div>
                            <div class="light-yallow"></div>
                            <div class="light-sky"></div>
                            <div class="light-blue"></div>
                            <div class="light-purple"></div>
                          </div>
                          <div class="value">
                            <div>
                              <p>Initial</p>
                            </div>
                            <div>
                              <p>Warm Up</p>
                            </div>
                            <div>
                              <p>Week - 1</p>
                            </div>
                            <div>
                              <p>Week - 2</p>
                            </div>
                            <div>
                              <p>Week - 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="divider"></div>
            </div>
            <div class="cardio-vid crossfit">
              <div class="container-fluid">
                <div class="row">
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <div class="video-wrap">
                      <iframe
                        frameborder="0"
                        allowfullscreen="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        title="15 MIN HIIT Full Body Workout – Intermediate Level"
                        width="100%"
                        height="250px"
                        src="https://www.youtube.com/embed/ViqOmHQf6q0?enablejsapi=1&amp;origin=https%3A%2F%2Fwww.fittr.com&amp;widgetid=1"
                        id="widget2"
                        data-gtm-yt-inspected-9="true"
                      ></iframe>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-left"
                    data-aos-duration="1000"
                  >
                    <div class="work-content">
                      <h2>Cardio &amp; Abs Home Workout</h2>
                      <div class="focus-area short-description mb-4">
                        <h3>Description</h3>
                        <p>
                          It is a long Established fact that a reader a page
                          when looking at its layout.It is a long Established
                          fact that a reader a page when looking at its
                          layout.It is a long Established fact that a reader a
                          page when looking at its layout.
                        </p>
                      </div>
                      <div class="focus-area mb-4">
                        <h3>Focus Areas</h3>
                        <ul class="mb-4">
                          <li>Flexibility</li>
                          <li>Stress Reduction</li>
                        </ul>
                        <ul>
                          <li>Warm Up</li>
                          <li>Mental &amp; Emotional Well Being</li>
                        </ul>
                      </div>
                      <div class="focus-area workout-details mb-4">
                        <h3>Workout Details</h3>
                        <ul>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="icon">
                                <img src="img/level-intermediate.png" alt="" />
                              </div>
                              <h4>Intensity</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/fluent-mdl2_calories.png"
                                  alt=""
                                />
                                <div class="media-body">250 Kcal</div>
                              </div>
                              <h4>CALORIES</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/mdi_stopwatch-stop.png"
                                  alt=""
                                />
                                <div class="media-body">20 Min</div>
                              </div>
                              <h4>Time</h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="barContainer">
                        <div class="mb-5">
                          <h3>insights</h3>
                          <div class="bar">
                            <div class="light-red"></div>
                            <div class="light-yallow"></div>
                            <div class="light-sky"></div>
                            <div class="light-blue"></div>
                            <div class="light-purple"></div>
                          </div>
                          <div class="value">
                            <div>
                              <p>Initial</p>
                            </div>
                            <div>
                              <p>Warm Up</p>
                            </div>
                            <div>
                              <p>Week - 1</p>
                            </div>
                            <div>
                              <p>Week - 2</p>
                            </div>
                            <div>
                              <p>Week - 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="divider"></div>
            </div>
            <div class="cardio-vid crossfit">
              <div class="container-fluid">
                <div class="row">
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <div class="video-wrap">
                      <iframe
                        frameborder="0"
                        allowfullscreen="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        title="15 MIN HIIT Full Body Workout – Intermediate Level"
                        width="100%"
                        height="250px"
                        src="https://www.youtube.com/embed/ViqOmHQf6q0?enablejsapi=1&amp;origin=https%3A%2F%2Fwww.fittr.com&amp;widgetid=1"
                        id="widget2"
                        data-gtm-yt-inspected-9="true"
                      ></iframe>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-left"
                    data-aos-duration="1000"
                  >
                    <div class="work-content">
                      <h2>Cardio &amp; Abs Home Workout</h2>
                      <div class="focus-area short-description mb-4">
                        <h3>Description</h3>
                        <p>
                          It is a long Established fact that a reader a page
                          when looking at its layout.It is a long Established
                          fact that a reader a page when looking at its
                          layout.It is a long Established fact that a reader a
                          page when looking at its layout.
                        </p>
                      </div>
                      <div class="focus-area mb-4">
                        <h3>Focus Areas</h3>
                        <ul class="mb-4">
                          <li>Flexibility</li>
                          <li>Stress Reduction</li>
                        </ul>
                        <ul>
                          <li>Warm Up</li>
                          <li>Mental &amp; Emotional Well Being</li>
                        </ul>
                      </div>
                      <div class="focus-area workout-details mb-4">
                        <h3>Workout Details</h3>
                        <ul>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="icon">
                                <img src="img/level-intermediate.png" alt="" />
                              </div>
                              <h4>Intensity</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/fluent-mdl2_calories.png"
                                  alt=""
                                />
                                <div class="media-body">250 Kcal</div>
                              </div>
                              <h4>CALORIES</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/mdi_stopwatch-stop.png"
                                  alt=""
                                />
                                <div class="media-body">20 Min</div>
                              </div>
                              <h4>Time</h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="barContainer">
                        <div class="mb-5">
                          <h3>insights</h3>
                          <div class="bar">
                            <div class="light-red"></div>
                            <div class="light-yallow"></div>
                            <div class="light-sky"></div>
                            <div class="light-blue"></div>
                            <div class="light-purple"></div>
                          </div>
                          <div class="value">
                            <div>
                              <p>Initial</p>
                            </div>
                            <div>
                              <p>Warm Up</p>
                            </div>
                            <div>
                              <p>Week - 1</p>
                            </div>
                            <div>
                              <p>Week - 2</p>
                            </div>
                            <div>
                              <p>Week - 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="divider"></div>
            </div>
            <div class="cardio-vid crossfit">
              <div class="container-fluid">
                <div class="row">
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <div class="video-wrap">
                      <iframe
                        frameborder="0"
                        allowfullscreen="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        title="15 MIN HIIT Full Body Workout – Intermediate Level"
                        width="100%"
                        height="250px"
                        src="https://www.youtube.com/embed/ViqOmHQf6q0?enablejsapi=1&amp;origin=https%3A%2F%2Fwww.fittr.com&amp;widgetid=1"
                        id="widget2"
                        data-gtm-yt-inspected-9="true"
                      ></iframe>
                    </div>
                  </div>
                  <div class="col-md-6" data-aos="zoom-out-left">
                    <div class="work-content">
                      <h2>Cardio &amp; Abs Home Workout</h2>
                      <div class="focus-area short-description mb-4">
                        <h3>Description</h3>
                        <p>
                          It is a long Established fact that a reader a page
                          when looking at its layout.It is a long Established
                          fact that a reader a page when looking at its
                          layout.It is a long Established fact that a reader a
                          page when looking at its layout.
                        </p>
                      </div>
                      <div class="focus-area mb-4">
                        <h3>Focus Areas</h3>
                        <ul class="mb-4">
                          <li>Flexibility</li>
                          <li>Stress Reduction</li>
                        </ul>
                        <ul>
                          <li>Warm Up</li>
                          <li>Mental &amp; Emotional Well Being</li>
                        </ul>
                      </div>
                      <div class="focus-area workout-details mb-4">
                        <h3>Workout Details</h3>
                        <ul>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="icon">
                                <img src="img/level-intermediate.png" alt="" />
                              </div>
                              <h4>Intensity</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/fluent-mdl2_calories.png"
                                  alt=""
                                />
                                <div class="media-body">250 Kcal</div>
                              </div>
                              <h4>CALORIES</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/mdi_stopwatch-stop.png"
                                  alt=""
                                />
                                <div class="media-body">20 Min</div>
                              </div>
                              <h4>Time</h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="barContainer">
                        <div class="mb-5">
                          <h3>insights</h3>
                          <div class="bar">
                            <div class="light-red"></div>
                            <div class="light-yallow"></div>
                            <div class="light-sky"></div>
                            <div class="light-blue"></div>
                            <div class="light-purple"></div>
                          </div>
                          <div class="value">
                            <div>
                              <p>Initial</p>
                            </div>
                            <div>
                              <p>Warm Up</p>
                            </div>
                            <div>
                              <p>Week - 1</p>
                            </div>
                            <div>
                              <p>Week - 2</p>
                            </div>
                            <div>
                              <p>Week - 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="divider"></div>
            </div>
            <div class="cardio-vid crossfit">
              <div class="container-fluid">
                <div class="row">
                  <div
                    class="col-md-6"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <div class="video-wrap">
                      <iframe
                        frameborder="0"
                        allowfullscreen="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        title="15 MIN HIIT Full Body Workout – Intermediate Level"
                        width="100%"
                        height="250px"
                        src="https://www.youtube.com/embed/ViqOmHQf6q0?enablejsapi=1&amp;origin=https%3A%2F%2Fwww.fittr.com&amp;widgetid=1"
                        id="widget2"
                        data-gtm-yt-inspected-9="true"
                      ></iframe>
                    </div>
                  </div>
                  <div class="col-md-6" data-aos="zoom-out-left">
                    <div class="work-content">
                      <h2>Cardio &amp; Abs Home Workout</h2>
                      <div class="focus-area short-description mb-4">
                        <h3>Description</h3>
                        <p>
                          It is a long Established fact that a reader a page
                          when looking at its layout.It is a long Established
                          fact that a reader a page when looking at its
                          layout.It is a long Established fact that a reader a
                          page when looking at its layout.
                        </p>
                      </div>
                      <div class="focus-area mb-4">
                        <h3>Focus Areas</h3>
                        <ul class="mb-4">
                          <li>Flexibility</li>
                          <li>Stress Reduction</li>
                        </ul>
                        <ul>
                          <li>Warm Up</li>
                          <li>Mental &amp; Emotional Well Being</li>
                        </ul>
                      </div>
                      <div class="focus-area workout-details mb-4">
                        <h3>Workout Details</h3>
                        <ul>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="icon">
                                <img src="img/level-intermediate.png" alt="" />
                              </div>
                              <h4>Intensity</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/fluent-mdl2_calories.png"
                                  alt=""
                                />
                                <div class="media-body">250 Kcal</div>
                              </div>
                              <h4>CALORIES</h4>
                            </div>
                          </li>
                          <li>
                            <div class="d-inline-block text-center">
                              <div class="media">
                                <img
                                  class="mr-1"
                                  src="img/mdi_stopwatch-stop.png"
                                  alt=""
                                />
                                <div class="media-body">20 Min</div>
                              </div>
                              <h4>Time</h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="barContainer">
                        <div class="mb-5">
                          <h3>insights</h3>
                          <div class="bar">
                            <div class="light-red"></div>
                            <div class="light-yallow"></div>
                            <div class="light-sky"></div>
                            <div class="light-blue"></div>
                            <div class="light-purple"></div>
                          </div>
                          <div class="value">
                            <div>
                              <p>Initial</p>
                            </div>
                            <div>
                              <p>Warm Up</p>
                            </div>
                            <div>
                              <p>Week - 1</p>
                            </div>
                            <div>
                              <p>Week - 2</p>
                            </div>
                            <div>
                              <p>Week - 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="divider"></div>
            </div>
            <div class="text-center">
              <div class="pagination">
                {/* <ul> <!--pages or li are comes from javascript --> </ul> */}
              </div>
            </div>
          </div>
          <div
            class="tab-pane"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="row blog-slider">
              <div class="col-lg-4">
                <div class="blog-box">
                  <div class="blog-img">
                    <img src="img/blog-img4.jpg" alt="" />
                  </div>
                  <div class="blog-head d-flex justify-content-between align-center">
                    <div class="blog-left">Fitness</div>
                    <div class="blog-right d-flex align-center">
                      <ul>
                        <li>
                          <i class="ti-calendar"></i> December 14,2021
                        </li>
                        <li>
                          <i class="ti-book"></i> 12 Min Read
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="short-content">
                    <p>The Healthy life, the best</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-box">
                  <div class="blog-img">
                    <img src="img/blog-img5.png" alt="" />
                  </div>
                  <div class="blog-head d-flex justify-content-between align-center">
                    <div class="blog-left">Lifestyle</div>
                    <div class="blog-right d-flex align-center">
                      <ul>
                        <li>
                          <i class="ti-calendar"></i> July 05,2021
                        </li>
                        <li>
                          <i class="ti-book"></i> 12 Min Read
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="short-content">
                    <p>Five easy and quik lunchbox</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-box">
                  <div class="blog-img">
                    <img src="img/blog-img6.png" alt="" />
                  </div>
                  <div class="blog-head d-flex justify-content-between align-center">
                    <div class="blog-left">Nutrition</div>
                    <div class="blog-right d-flex align-center">
                      <ul>
                        <li>
                          <i class="ti-calendar"></i> April 05,2021
                        </li>
                        <li>
                          <i class="ti-book"></i> 12 Min Read
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="short-content">
                    <p>You deserve great health!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane"
            id="meditation"
            role="tabpanel"
            aria-labelledby="meditation-tab"
          >
            <div class="row blog-slider">
              <div class="col-lg-4">
                <div class="blog-box">
                  <div class="blog-img">
                    <img src="img/blog-img4.jpg" alt="" />
                  </div>
                  <div class="blog-head d-flex justify-content-between align-center">
                    <div class="blog-left">Fitness</div>
                    <div class="blog-right d-flex align-center">
                      <ul>
                        <li>
                          <i class="ti-calendar"></i> December 14,2021
                        </li>
                        <li>
                          <i class="ti-book"></i> 12 Min Read
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="short-content">
                    <p>The Healthy life, the best</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-box">
                  <div class="blog-img">
                    <img src="img/blog-img5.png" alt="" />
                  </div>
                  <div class="blog-head d-flex justify-content-between align-center">
                    <div class="blog-left">Lifestyle</div>
                    <div class="blog-right d-flex align-center">
                      <ul>
                        <li>
                          <i class="ti-calendar"></i> July 05,2021
                        </li>
                        <li>
                          <i class="ti-book"></i> 12 Min Read
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="short-content">
                    <p>Five easy and quik lunchbox</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-box">
                  <div class="blog-img">
                    <img src="img/blog-img6.png" alt="" />
                  </div>
                  <div class="blog-head d-flex justify-content-between align-center">
                    <div class="blog-left">Nutrition</div>
                    <div class="blog-right d-flex align-center">
                      <ul>
                        <li>
                          <i class="ti-calendar"></i> April 05,2021
                        </li>
                        <li>
                          <i class="ti-book"></i> 12 Min Read
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="short-content">
                    <p>You deserve great health!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default WorkoutDetail;
