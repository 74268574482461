import React from "react";

const NewHome = () => {
  return (
    <div>
      <div>
        <video
          id="bgVideo"
          className="w-full h-full fixed object-cover"
          autoPlay
          loop
          muted
        >
          <source src="video/full_video.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="relative z-50 text-white bg-black bg-opacity-60 w-full h-full lg:h-[100vh]">
        <div className="h-full flex justify-center items-center">
          <div className="w-[620px] h-full flex flex-col justify-center items-center relative bottom-0 py-14">
            <div>
              <div className="flex justify-center items-center mb-5 w-full">
                <img src="img/logo-icon.png" className="mr-2" alt="" />
                <div>
                  <div className="text-[42px] mb-2 font-semibold leading-[22px]">
                    FitnEarn
                  </div>
                  <div className="text-gray-300 italic font-medium text-center w-full">
                    Exercise, Earn, Enjoy
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center text-[28px] leading-[34px] my-3 font-medium">
              Step to a healthier and happier You, starts now! Earn While you
              exercise, connect with a vibrant community, and make every workout
              count!
            </div>

            <div className="text-center text-[36px] mt-5 font-semibold">
              Download App Now
            </div>

            <div className="flex justify-center mt-8">
              <div className="flex space-x-3">
                <a target="__blank" href="https://apps.apple.com/in/app/fitnearn-exercise-earn-enjoy/id6476557022">
                  <img
                    width="140"
                    className="object-contain"
                    src="img/app-store.png"
                    alt=""
                  />
                </a>
                <a target="__blank" href="https://play.google.com/store/apps/details?id=com.fitearn.meditate&pcampaignid=web_share">
                  <img
                    width="140"
                    className="object-contain"
                    src="img/google-play.png"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <div className="text-center text-[36px] mt-5">
              <div className="mb-4 text-[20px] uppercase">Our Website is</div>
              <div>Coming soon!</div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative text-gray-500 z-50 text-[14px] bg-[#000000cf]">
        <div className="flex flex-col lg:flex-row sm:items-center justify-between w-full px-2 py-10 gap-2">
          <div className="flex flex-col items-center">
            <ul className="flex space-x-3">
              <li>
                <a className="text-gray-300" href="javascript:void(0);">
                  <i className="ti-control-play" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a className="text-gray-300" href="javascript:void(0);">
                  <i className="ti-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a className="text-gray-300" href="javascript:void(0);">
                  <i className="ti-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a className="text-gray-300" href="javascript:void(0);">
                  <i className="ti-twitter-alt" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a className="text-gray-300" href="javascript:void(0);">
                  <i className="ti-linkedin" aria-hidden="true"></i>
                </a>
              </li>
            </ul>

            <div className="text-[12px] text-center text-gray-400 mt-1">
              Follow us for Recent Updates
            </div>
          </div>

          <div className="relative mr-5 text-center flex flex-col items-center text-gray-400 w-full">
            <div>Copyright© 2023-24. All Rights Reserved</div>

            <ul className="flex lg:space-x-5 text-[10px] mt-2">
              <li>
                <a className="text-gray-400" href="/About-Us" target="__blank">
                  About Us
                </a>
              </li>
              <li>
                <a
                  className="text-gray-400"
                  href="/privacy-policy"
                  target="__blank"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="text-gray-400"
                  href="/terms-conditions"
                  target="__blank"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  className="text-gray-400"
                  href="/Refund-And-Return-Policy"
                  target="__blank"
                >
                  Return and refund policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHome;
