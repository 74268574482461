import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import Workout from "./Pages/Workout";
import SingleBlog from "./Pages/SingleBlog";
import WorkoutDetail from "./Pages/WorkoutDetail";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import NewHome from "./Pages/NewHome";
import AboutUs from "./Pages/AboutUs";
import ReturnAndRefundPolicy from "./Pages/ReturnAndRefundPolicy";

function App() {
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route exact path="/" element={<NewHome />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/workout" element={<Workout />} />
          <Route exact path="/workoutdetail" element={<WorkoutDetail />} />
          <Route exact path="/blog/:id" element={<SingleBlog />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" element={<TermsAndConditions />} />
          <Route exact path="/About-Us" element={<AboutUs />} />
          <Route exact path="/Refund-And-Return-Policy" element={<ReturnAndRefundPolicy />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
