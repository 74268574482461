import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <header class="header" data-aos="fade-down" data-aos-duration="1000">
        <div class="container-fluid d-flex justify-content-between">
          <div class="logo d-flex align-items-center">
            <img src="img/logo-icon.png" class="mr-2" alt="" />{" "}
            <span>FitnEarn</span>
          </div>
          <div class="nav" id="cssmenu">
            <ul>
              <li class="current">
                <Link to={"/"} title="Home">
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/workout"} title="workout">
                  Workout
                </Link>
              </li>
              <li>
                <Link to={"/blog"} title="Blog">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div class="store-btn">
            <ul>
              <li class="mr-3">
                <img src="img/google-play.png" alt="" />
              </li>
              <li class="mr-3">
                <img src="img/app-store.png" alt="" />
              </li>
              <li>
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0">LiveSession</h5>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
