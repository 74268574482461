import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiUrl from "../../Utils/ApiUrl";
import { Link } from "react-router-dom";

function WorkoutList({tag}) {
  const [workoutList, setWorkoutList] = useState([]);
  useEffect(() => {
    console.log(`${ApiUrl.workout}?tag=${tag}`);
    axios
      .get(`${ApiUrl.workout}?tag=${tag}`)
      .then((res) => {
        console.log('workout list data')
        console.log(res.data.data);
        setWorkoutList(res.data.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [tag]);
  return (
    <>
      {workoutList.length > 0 &&
        workoutList.map((item) => (
          <div class="col-lg-4">
            {console.log("inside data")}
            {console.log(tag)}
            {console.log(item)}
            <div class="portfolio-box wow fadeInUp" data-wow-delay=".5s">
              <Link to={'/workoutdetail'}  state={{data: item.category}}>
                <div class="portfolio-img">
                  <div class="overlay"></div>
                  <img
                    src={`http://localhost:3000/image/${item.thumbnailImage}`}
                    alt=""
                  />
                </div>
                <div class="portfolio-content">
                  <h3 class="h3-title">{item.category }</h3>
                  <p>
                    {item.title}
                  </p>
                </div>
              </Link>
              <div class="work-bottom d-flex justify-content-between align-center">
                <div class="work-left">{item.level}</div>
                <div class="work-right">
                  <ul>
                    <li>
                      <img src="img/mdi_stopwatch-stop.png" alt="" /> {item.time} MIN
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default WorkoutList;
