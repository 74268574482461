
function Footer() {
  return (
    <>
      <footer class="main-footer">
        <div data-aos="zoom-out" data-aos-duration="1000" class="news-letter">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="news-left">
                <ul>
                  <li>
                    <lottie-player
                      src="img/json/news-animation.json"
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <h3>SUBSCRIBE NOW</h3>
              <div class="footer-subscribe-form">
                <input
                  type="email"
                  name="email"
                  class="form-input subscribe-input"
                  placeholder="Email Address"
                  required=""
                />
                <button class="bubbly-button"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-overlay-bg animate-this">
          <img src="img/footer-overlay.png" alt="Overlay" />
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="footer-box-one">
                <a href="index.html">
                  <div class="logo d-flex align-items-center">
                    <img src="img/logo-icon.png" class="mr-2" alt="" />{" "}
                    <span>FitnEarn</span>
                  </div>
                </a>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
              <div class="footer-box-two">
                <h3 class="h3-title">Our Links</h3>
                <div class="line"></div>
                <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Classes</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="footer-box-three">
                <h3 class="h3-title">Contact Us</h3>
                <div class="line"></div>
                <ul>
                  <li>
                    <div class="footer-contact-icon">
                      <i class="ti-location-pin" aria-hidden="true"></i>
                    </div>
                    <div class="footer-contact-text">
                      <span>
                        1247/Plot No. 39, 15th Phase, Colony, Kukatpally,
                        Hyderabad
                      </span>
                    </div>
                  </li>
                  <li>
                    <div class="footer-contact-icon">
                      <i class="ti-mobile" aria-hidden="true"></i>
                    </div>
                    <div class="footer-contact-text">
                      <span>1800-121-3637</span>
                      <span>+91 555 234-8765</span>
                    </div>
                  </li>
                  <li>
                    <div class="footer-contact-icon">
                      <i class="ti-location-arrow" aria-hidden="true"></i>
                    </div>
                    <div class="footer-contact-text">
                      <span>info@gmail.com</span>
                      <span>services@gmail.com</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="footer-box-four">
                <h3 class="h3-title">Follow Us</h3>
                <div class="line"></div>
                <div class="footer-social">
                  <ul>
                    <li>
                      <a class="icon" href="javascript:void(0);">
                        <i class="ti-control-play" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a class="icon" href="javascript:void(0);">
                        <i class="ti-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a class="icon" href="javascript:void(0);">
                        <i class="ti-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a class="icon" href="javascript:void(0);">
                        <i class="ti-twitter-alt" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a class="icon" href="javascript:void(0);">
                        <i class="ti-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-7">
                <div class="copyright-text">
                  <span>
                    Copyright &copy; 2023 <a href="index.html">FitnEarn</a> All
                    rights reserved.
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-5">
                <div class="copyright-links">
                  <ul>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/terms-conditions">Team &amp; Condition</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
