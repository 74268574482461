import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ApiUrl from "../Utils/ApiUrl";
import { convertDateTime } from "../Utils/Datetimeformat";

function AllBlogList() {
  const [blogList, setBlogList] = useState([]);
  useEffect(() => {
    axios
      .get(ApiUrl.Blog)
      .then((res) => {
        console.log(res.data.data);
        setBlogList(res.data.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  },[]);
  return (
    <>
      {blogList.length > 0 &&
        blogList.map((item) => (
          <div class="col-lg-4">
            <div class="blog-box">
              <div class="blog-img">
                <div class="overlay"></div>
                <img
                  src={`http://localhost:3000/image/${item.thumbnailImage}`}
                  alt=""
                />
              </div>
              <div class="blog-head d-flex justify-content-between align-center">
                <div class="blog-left">{item.category}</div>
                <div class="blog-right d-flex align-center">
                  <ul>
                    <li>
                      <i class="ti-calendar"></i> {convertDateTime(new Date())}
                    </li>
                    <li>
                      <i class="ti-book"></i> {item.readingTime} Min Read
                    </li>
                  </ul>
                </div>
              </div>
              <div class="short-content">
                <p>
                  {item.title.substring(0,40)}...{" "}
                  <Link to ={`/blog/${item._id}`}>READ MORE</Link>
                </p>  
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default AllBlogList;
