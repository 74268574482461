import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <div
        style={{ padding: "60px 100px", background: "#f5f5f5", color: "black" }}
      >
        <div
          className="about-us"
          style={{ padding: "80px", borderRadius: "4px" }}
        >
          <div>
            <div style={{ clear: "both" }}>
              <p>&nbsp;</p>
            </div>
            <p style={{ textAlign: "center", lineHeight: "120%", fontSize: "14pt" }}>
              <strong>
                <span style={{ fontSize: 36 }}>ABOUT US</span>
              </strong>
            </p>
            <p style={{ textAlign: "center", lineHeight: "120%", fontSize: "14pt" }}>
              <strong>
                <span style={{ fontSize: 36 }}>&nbsp;</span>
              </strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              Welcome to FitnEarn, where fitness meets reward in an innovative and
              engaging way. Our platform is designed to inspire, motivate, and support
              your journey towards a healthier lifestyle, all while offering you the
              chance to earn rewards as you achieve your fitness goals.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Our Mission</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              At FitnEarn, we believe in the power of combining health and wellness with
              the excitement of earning rewards. Our mission is to create a community
              where individuals are encouraged to embrace fitness as a way of life,
              supported by cutting-edge technology, expert guidance, and an incentivising
              rewards system. We aim to make fitness accessible and enjoyable for
              everyone, regardless of their starting point.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Our Vision</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              We envision a world where fitness and well-being are prioritised and
              rewarded. FitnEarn seeks to be at the forefront of this movement, creating a
              platform that not only supports physical health but also fosters a sense of
              achievement and motivation through tangible rewards. Our vision is to build
              a global community that values health, supports each other, and celebrates
              every step towards a healthier life.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Our Services</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              FitnEarn offers a diverse range of services tailored to meet your fitness
              needs:
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Customized Fitness Plans:</strong> Whether you're a beginner or a
              seasoned athlete, our platform provides personalized workout plans designed
              by fitness experts to help you reach your goals.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Rewards System:</strong> Earn points as you complete workouts,
              participate in challenges, and achieve your goals. These points can be
              redeemed for a variety of rewards, including fitness gear, healthy foods,
              and more.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Social Engagement</strong>: Join a vibrant community of fitness
              enthusiasts. Share your journey, seek advice, and find motivation from peers
              who are on the same path as you.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Our Story</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              Founded in Roorkee, Uttarakhand, by a team of fitness enthusiasts and tech
              innovators, FitnEarn was born out of a desire to merge the worlds of fitness
              and technology. Recognizing the need for a platform that not only guides
              individuals on their fitness journey but also provides an extra layer of
              motivation, we set out to create FitnEarn. Today, we are proud to offer a
              comprehensive platform that has helped countless users transform their
              lives, one reward at a time.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Data Deletion Information</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              Within our app, you have the ability to manage and delete your personal
              information through the "Delete Account" feature located in the Platform
              settings. It's important to note that we do not retain any personal
              information of our users. However, certain information may be kept for
              record-keeping purposes as required by law. This retention is essential for
              preventing fraud, enforcing our Platform Terms and Conditions, protecting
              the integrity of our Platform, and complying with legal obligations.
            </p>
            <p style={{ textAlign: "justify" }}>
              Users have the right to access, modify, or delete their personal information
              stored within the FitnEarn application. To exercise these rights, users can
              navigate to the settings section within the application, where they will
              find options to modify, manage and delete their account data. Additionally,
              users can contact our support team at help-support@fitnearn.com for
              assistance with data deletion requests.
            </p>
            <p style={{ textAlign: "justify" }}>
              We are committed to promptly addressing user requests regarding data
              deletion in accordance with applicable privacy laws and regulations.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Join Us</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              Whether you're looking to start your fitness journey, seeking motivation to
              continue, or aiming to reach new heights in your wellness goals, FitnEarn is
              here for you. Let's embark on this rewarding journey together. Your fitness
              achievements await, and we can't wait to see where they take you.
            </p>
            <p style={{ textAlign: "justify" }}>
              <br />
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Contact Us</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Address:&nbsp;</strong>395, Purav Deen Dayal, Old Railway Road, Veer
              Bhawan Nagar, Roorkee-247667, India
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Phone:</strong> +91-8630222654
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Email:</strong> help-support@fitnearn.com
            </p>
            <p style={{ textAlign: "justify" }}>&nbsp;</p>
            <p style={{ textAlign: "justify" }}>
              Experience the joy of fitness with FitnEarn. Where every step towards health
              is rewarded, every milestone celebrated, and every goal achieved brings not
              just physical benefits, but tangible rewards. Welcome to the future of
              fitness. Welcome to FitnEarn.
            </p>
            <p style={{ textAlign: "justify" }}>&nbsp;</p>
            <div style={{ clear: "both" }}>
              <p>&nbsp;</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
