

const TermsAndConditions = () => {
    return (
        <div style={{ padding: "60px 100px", background: "#f5f5f5", color: "black" }}>
            <div style={{ padding: "80px", borderRadius: "4px" }}>
                <div>
                    <div style={{ clear: "both" }}>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "center",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span size={4} style={{ fontSize: 21 }}>
                                <strong>TERMS AND CONDITIONS</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "center",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            Last updated: <strong>04.02.2024</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Welcome to FitnEarn (the “Platform”), an online platform dedicated to
                            fitness and wellness, operated by Fit Earn Meditate (“FitnEarn”, "Sole
                            Proprietorship”, “we,” “us,” or “our”). FitnEarn's office is located
                            at&nbsp;
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                395, Purav Deen Dayal, Old Railway Road, Veer Bhawan Nagar,
                                                Roorkee-247667
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <strong>,&nbsp;</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            This document is an electronic record in terms of the Information
                            Technology Act, 2000, and rules thereunder, and is published in accordance
                            with the provisions of the Information Technology (Reasonable Security
                            Practices and Procedures and Sensitive Personal Data or Information)
                            Rules, 2011, which require publishing the rules and regulations, privacy
                            policy, and Terms and Conditions for access or usage of the Platform
                            through the FitnEarn Mobile Application
                            <span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;</span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <em>(hereinafter referred to as “Mobile Application”)</em>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;</span>and Website – [
                            <a href="http://www.fitnearn.com/">
                                <span style={{ color: "rgb(0, 0, 255)" }}>
                                    <u>www.fitnearn.com</u>
                                </span>
                            </a>
                            ]<span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;</span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <em>(hereinafter referred to as "Website")&nbsp;</em>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                and our related Website, Application, Services, Products,Devices and
                                content (together with the Mobile Application and Website, collectively
                                referred to as “Services”).
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>AGREEMENT TO TERMS</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <u>User Agreement:</u>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                &nbsp;These Terms and Conditions constitute a legally binding agreement
                                made between you, whether personally or on behalf of an entity (“you” or
                                “user” or “client”) and FitnEarn (Fit Earn Meditate), concerning your
                                access to and use of the Website and FitnEarn Mobile Application as well
                                as any other media form, media channel, mobile website or mobile
                                application related, linked, or otherwise connected thereto
                                (collectively, the "Website and Mobile Application”). You agree that by
                                accessing the Website and/or Mobile Application, you have read,
                                understood, and agree to be bound by all of these Terms and Conditions.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>
                                IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND CONDITIONS, THEN YOU ARE
                                EXPRESSLY PROHIBITED FROM USING THE SERVICES AND MUST DISCONTINUE USE
                                IMMEDIATELY.
                            </strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>Changes to Terms &amp; Conditions</u>
                            <span style={{ textDecoration: "none" }}>: Any&nbsp;</span>Supplementary
                            terms and conditions or documents that may be occasionally published on
                            the FitnEarn Website or Mobile App are integrated by reference into this
                            agreement. FitnEarn reserves the exclusive right to amend or update these
                            Terms at any moment for any reason. Changes will be communicated by
                            updating the "<strong>Last Updated</strong>" date of these Terms and
                            Conditions.&nbsp;
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                &nbsp;It is your responsibility to review these Terms and
                                Conditions&nbsp;
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                periodically&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                to stay informed of updates. You will be subject to, and will be deemed
                                to have been made aware of and to have accepted, the changes in any
                                revised Terms and Conditions by continuing to access or use the FitnEarn
                                Website or Mobile Application, or any other services offered after these
                                updates take effect, you acknowledge and accept the revised Terms and
                                Conditions.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                The information provided on the Website/Mobile Application is
                                                not intended for distribution to or use by any person or entity
                                                in any jurisdiction or country where such distribution or use
                                                would be contrary to law or regulation or which would subject us
                                                to any registration requirement within such jurisdiction or
                                                country. Accordingly, those persons who choose to access the
                                                Website/Mobile Application from other locations do so on their
                                                own initiative and are solely responsible for compliance with
                                                local laws, if and to the extent local laws are applicable.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <u>Eligibility:</u>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            You must be at least 18 to register on the Platform. If you are below the
                            ages of 18 (“Minor”), you may use our Services only with the supervision
                            and consent of a parent or guardian. No individual under these age limits
                            may use our Services, provide any Personal Data to FitnEarn, or otherwise
                            submit Personal Data through the Services (e.g., a name, address,
                            telephone number, or email address).&nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                As a minor if you wish to use Our Products or Services, such use
                                                shall be made available to You by Your legal guardian or
                                                parents, who has agreed to these Terms. In the event a minor
                                                utilises the Application/Website/Services, it is assumed that
                                                he/she has obtained the consent of the legal guardian or parents
                                                and such use is made available by the legal guardian or parents.
                                                The Company will not be responsible for any consequence that
                                                arises due to, misuse of any kind of Our Website or any of Our
                                                Products or Services that may occur by any person including a
                                                minor registering for the Services/Products provided. By using
                                                the Products or Services You warrant that all the data provided
                                                by You is accurate and complete and that the Minor using the
                                                Website has obtained the consent of parent/legal guardian (in
                                                case of minors). The Company reserves the right to terminate
                                                Your account and / or refuse to provide You with access to the
                                                Products or Services if it is discovered that You are under the
                                                age of 18 (Eighteen) years and the consent to use the Products
                                                or Services is not made by Your parent/legal guardian or any
                                                information provided by You is inaccurate. You acknowledge that
                                                the Company does not have the responsibility to ensure that You
                                                conform to the eligibility criteria mentioned above. It shall be
                                                Your sole responsibility to ensure that You meet the required
                                                qualification. Any persons under the age of 18 (Eighteen) should
                                                seek the consent of their parents/legal guardians before
                                                providing any Information about themselves or their parents and
                                                other family members on the Website.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>OUR SERVICES</strong>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Our Services allow you to purchase various Products and services
                                                from us. We reserve the right to amend, discontinue, withdraw,
                                                or cease our service offerings at any time. FitnEarn offers a
                                                variety of services aimed at enhancing your fitness and wellness
                                                journey, including but not limited to:
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>a)&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                Workout Plans:&nbsp;
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                A personalised workout plan recommended by FitnEarn and
                                                customised workout plan based on user requirements both
                                                subscription based offering users to access existing media and
                                                content relating to various exercises from categories such as
                                                Yoga, Muscle Building, Dance Fitness, Cardio, Kick-boxing,
                                                Plyomterics, Meditation, Stretching and Rehab &amp; Care etc.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontWeight: "normal" }}>b)&nbsp;</span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>Exercise Video Library</strong>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                : Access our exercise video library which comprises exercises in various
                                categories such as Yoga, Pilates, Body Weight, Cardio, Equipment Based
                                Workouts, Meditation etc.&nbsp;
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontWeight: "normal" }}>c)</span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>&nbsp;FitnEarn Market Place</strong>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                : Access to purchase of various products/accessories related to health
                                and fitness from FitnEarn Store.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontWeight: "normal" }}>d)</span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>&nbsp;Exercise Challenges:</strong>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                &nbsp;Participate in various challenges for motivation and rewards.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>e)</span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                &nbsp;Community Engagement:&nbsp;
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Access to our social feed section to engage and communicate with
                                                the FitnEarn community in compliance with the community
                                                guidelines.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>HEALTH DISCLAIMER</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                In consideration of being allowed to participate and use any of
                                                the services offered by FitnEarn, in addition to the payment of
                                                any fee or charge, you do hereby waive, release and forever
                                                discharge and hold harmless FitnEarn and its coaches,
                                                consultants, officers, agents, and employees from any and all
                                                responsibility, liability, costs and expenses, including
                                                injuries or damages, resulting from your participation and use
                                                of any of the services offered by FitnEarn.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                You do also hereby release FitnEarn, its coaches, consultants,
                                                officers, agents and employees from any responsibility or
                                                liability for any injury, damage or disorder (physical or
                                                otherwise) to you, or in anyway arising out of or connected with
                                                your participation in any activities and use of any of services
                                                associated or provided by the FitnEarn.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                You understand and you are aware that strength, flexibility,
                                                aerobic and other forms of exercise, asanas and practices
                                                including the use of equipment or otherwise are a potentially
                                                dangerous activity. You also understand that fitness activities
                                                involve a risk of loss of personal property, serious injury and
                                                even death, and that you are voluntarily participating in these
                                                activities and using equipment and machinery or otherwise with
                                                knowledge of the risk involved. You hereby agree to assume
                                                expressly and accept all risks of loss of personal property,
                                                serious injury or death related to said fitness activities. In
                                                addition, You certify that you are 18 years of age or older. You
                                                do hereby further declare yourself to be physically sound and
                                                suffering from no condition, impairment, disease, infirmity or
                                                other illness that would affect nutrient metabolism or prevent
                                                your participation or use of equipment or machinery or otherwise
                                                except as herein after stated.&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Moreover, you also agree that if you are suffering from any
                                                ailment or any medical condition you have to inform and produce
                                                relevant documents to FitnEarn before beginning any physical
                                                activities or any other plans that may be referenced, discussed
                                                or offered under the Services provided by FitnEarn platform. You
                                                do hereby acknowledge that FitnEarn has recommended to you to
                                                obtain an approval from a medical expert or certified medical
                                                practitioner as per the laws of the land prior to your
                                                participation in an exercise/fitness activity or in the use of
                                                any health and fitness services offered by FitnEarn. You also
                                                acknowledge that FitnEarn has recommended that you have a yearly
                                                or more frequent physical examination and consultation with your
                                                physician or any medical expert/ consultant prior to any
                                                physical activity, exercise and use of exercise and training
                                                equipment so that you might have his/her recommendations
                                                concerning these fitness activities and use of equipment. You
                                                acknowledge that you have either had a physical examination and
                                                been given your physician’s or medical expert's permission to
                                                participate, or that you have decided to participate in activity
                                                and use of equipment, machinery, plans, and other programs
                                                designed by FitnEarn without the approval of your physician or
                                                medical expert and do hereby assume and take all responsibility
                                                for your participation and activities, and utilization of
                                                fitness services provided by FitnEarn.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                FITNEARN DOES NOT PROVIDE PROFESSIONAL MEDICAL SERVICES OR
                                                ADVICE. THE SERVICES PROVIDED BY THE COACHES/TRAINING
                                                SPECIALISTS AND AVAILABLE ON THE WEBSITE AND/OR MOBILE APP DO
                                                NOT CONTAIN OR CONSTITUTE, AND SHOULD NOT BE INTERPRETED AS
                                                MEDICAL ADVICE OR OPINION. NO DOCTOR-PATIENT RELATIONSHIP IS
                                                CREATED. USE OF THE SERVICES IS NOT FOR MEDICAL EMERGENCIES. IF
                                                YOU THINK YOU HAVE A MEDICAL EMERGENCY, CONSULT YOUR DOCTOR.
                                                YOUR USE OF THE SERVICES DOES NOT CREATE A DOCTOR-PATIENT
                                                RELATIONSHIP BETWEEN YOU AND ANY OF THE FITNEARN ASSOCIATED
                                                COACHES, EMPLOYEES, OTHER FITNEARN ASSOCIATED PARTIES OR ANY OF
                                                FITNEARN SERVICE USERS.
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn, Fit Earn Meditate, FitnEarn Coin, FitnEarn Gift Cashback
                            Rewards, Product Reward System, Mood-board and associated challenges and
                            other Fitness and Meditation Challenge are sole proprietary of FitnEarn
                            (alias Fit Earn Meditate). Unless otherwise indicated, the Website/Mobile
                            Application, is our proprietary property and all content, source code,
                            databases, functionality, software, website designs, videos, text, images,
                            photographs, questions, creative suggestions, messages, comments,
                            feedback, ideas, drawings, articles and other materials, on the
                            Website/Mobile Application (collectively, the “Content”) and the
                            trademarks, service marks, and logos contained therein (the “Marks”) are
                            owned or controlled by us or licensed to us, and are protected by
                            copyright and trademark laws and various other intellectual property
                            rights and unfair competition laws of India, foreign jurisdictions, and
                            international conventions. The Content and the Marks are provided on the
                            Website/Mobile Application “AS IS” for your information and personal use
                            only. Except as expressly provided in these Terms and Conditions, no part
                            of the Website/Mobile Application and no Content or Marks may be modified,
                            copied, reproduced, aggregated, republished, uploaded, posted, publicly
                            displayed, performed, encoded, translated, transmitted, distributed, sold,
                            licensed, create derivative works of or otherwise exploited for any
                            commercial purpose whatsoever in whole or in part, without our express
                            prior written permission.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Provided that you are eligible to use the Website/Mobile Application, you
                            are granted a limited license to access and use the Website/Mobile
                            Application and to download or print a copy of any portion of the Content
                            to which you have properly gained access solely for your personal,
                            non-commercial use. We reserve all rights not expressly granted to you in
                            and to the Website/Mobile Application, the Content and the Marks.Our
                            commercial partners, suppliers, advertisers, sponsors, licensors,
                            contractors and other third parties may also have additional proprietary
                            rights in the Content which they make available on our Services. You may
                            not modify, publish, transmit, distribute, perform, participate in the
                            transfer or sale, create derivative works of, or in anyway exploit, any of
                            the Content, in whole or in part. When Content is downloaded to your
                            computer, phone, tablet or any other mobile device, you do not obtain any
                            ownership interest in such Content. Modification of the Content or use of
                            the Content for any other purpose, including, but not limited to, use of
                            any Content in printed form or on any other applications or networked
                            computer environment is strictly prohibited unless you receive our prior
                            written consent.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>USER REPRESENTATIONS</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                By using the FitnEarn Website/Mobile Application, you represent and
                                warrant that:
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <ol type="i">
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        all registration information you submit will be authentic, true,
                                        accurate, current, and complete.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        you will maintain the accuracy of such information and promptly
                                        update such registration information as necessary;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        you have the legal capacity and you agree to comply with these Terms
                                        and Conditions;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        you are not a minor in the jurisdiction in which you reside;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        you will not access the Website/Mobile Application through automated
                                        or non-human means, whether through a bot, script or otherwise;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        you will not use the Website/Mobile Application for any illegal or
                                        unauthorized purpose; and
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        your use of the Website/Mobile Application will not violate any
                                        applicable law or regulation.
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                If you provide any information that is untrue, inaccurate, not current,
                                or incomplete, we have the right to suspend or terminate your account
                                and refuse any current or future use of the Website/Mobile Application
                                (or any portion thereof) and may also take legal action as per
                                applicable laws of the land.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>USER REGISTRATION</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                You may be required to register with the Website/Mobile Application. You
                                agree to keep your password confidential and will be responsible for all
                                use of your account and password. We reserve the right to remove,
                                reclaim, or change a username you select if we determine, in our sole
                                discretion, that such username is inappropriate, obscene, or otherwise
                                objectionable.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>FITNEARN COIN TERMS AND CONDITIONS</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>General Conditions</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                1. You may avail the services of the FitnEarn Coins upon successful
                                registration and creation of an Account on the FitnEarn App or Website.
                                You are bound by these terms and conditions of the FitnEarnCoin System.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                2. By signing up, you agree that you have read and understood the Terms
                                and Conditions that govern the FitnEarnCoin's System, and give consent
                                to FitnEarn to contact you for events, promotions, product information
                                and discount. This will override the registry on NDNC/DND.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                3. Members can benefit from the FitnEarnCoin System by availing the
                                services on the FitnEarn App or Website.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                4. The FitnEarn Coin System is open to users of FitnEarn in India above
                                18 years of age only.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                5. FitnEarn Coin's System is for individuals only - it is not open to
                                corporates or companies.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                6. Registration on FitnEarn product must be in the applicant's full name
                                and mobile number is mandatory. Only one registration per individual
                                will be acknowledged.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                7. FitnEarn Coin balance is not transferable. The person named in
                                database must be present at the time of purchase to be eligible for
                                FitnEarn Coins available as per the System.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                8. Misuse of FitnEarn Coin's or other System benefits may result in
                                termination of membership or withdrawal of benefits at the sole
                                discretion of FitnEarn.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                9. No accumulation or redemption of FitnEarn Coins will be permissible
                                if, on relevant date, the FitnEarn Account has been deleted or is liable
                                to be deleted or if the account of the User is a defaulted account or if
                                there is any breach of any clause of these Terms and Conditions.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                10. The FitnEarn Coin's System is valid for use during the course of
                                FitnEarn Coin's System.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                11. FitnEarn reserves the right to refuse FitnEarn Coin's System to an
                                applicant without assigning any reason whatsoever.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                12. FitnEarn may suspend or terminate the Refer and Earn program or any
                                user's ability to participate in the program at any time for any reason
                                at their sole discretion.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                13. Usage conditions of FitnEarn Coins may change at the discretion of
                                FitnEarn, at any&nbsp;
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>point in time.</span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FitnEarn reserves the right to amend these terms and conditions at any
                                time without any prior notice. Modifications of these terms will be
                                effective from the time they are updated in the Terms and Conditions
                                section.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                A product purchased by redemption of FitnEarnCoins from FitnEarn store
                                is governed by and subject to the applicable Seller policies, including
                                applicable exchange and shipping policies. You agree that we are neither
                                the agents of any seller or manufacturer of the products and hence the
                                products quality offered are not under our control. Accordingly, we do
                                not assume any liability, obligation or responsibility for any
                                part/complete product. Due diligence must be done at your end before
                                purchasing any product either by Fitnearn coins or directly by
                                cash.&nbsp;
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>Earning FitnEarn Coins</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                A user can earn FitnEarn Coins by-
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                a. Performing/Watching any exercise video associated with Mood-Board
                                Challenges.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                b. Refeferring and registering new users on FitnEarn only on completion
                                of task associated with referral.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                c. Performing/Walking daily steps (tracking of the same is done by
                                Google Fit SDKs &amp; APIs) limiting up to 10000 for non premium users
                                and 20000 for premium users.&nbsp;
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                d. Participating in a Step, Exercise or Meditation Challenge on FitnEarn
                                Challenge Board.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                e. On purchase of a premium subscription plans on FitnEarn.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>f.&nbsp;</span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Performing/Watching any exercise video associated with Workout
                                                plans. This is available for premium users of FitnEarn.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>NOTE:</strong>
                            </span>
                            <br />
                            &nbsp;
                        </p>
                        <ul>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        FitnEarn coins cannot be purchased by users including by payment of
                                        cash.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        FitnEarn coins are rewards awarded to users of the app which can be
                                        redeemed for products listed on FitnEarn store.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        FitnEarn Coins can be exchanged with the FitnEarn Cashback Gift
                                        Rewards.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        The receipt of FitnEarn coins or it’s redemption by the end user
                                        should not be linked to money paid for the purchase of services on
                                        the Platform.&nbsp;
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                    THE COMPANY HEREBY CLARIFIES THAT FITNEARN COINS ARE BEING GRANTED BY
                                    US AT OUR DISCRETION ONLY.
                                </strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                By referring FitnEarn to your friends, both user and users friend will
                                receive 10 FitnEarn Coins each upon successful registration by users
                                friend on FitnEarn and completion of the task associated with the
                                referral. Users friend must not have installed the FitnEarn app on any
                                of their devices before. The Email ID and/or phone number through which
                                the friend signs up with FitnEarnapp, must not have been used for
                                signing up with FitnEarn earlier. The mobile number provided by the
                                friend to receive the OTP must not have been used to sign up earlier.
                                The device on which the users friend downloads the FitnEarnapp should
                                not be rooted or jail-broken. FitnEarn app should not be installed using
                                App runtime for Chrome, emulators or simulators.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                If your friends use someone else's referral link, the person whose link
                                was used first to download the FitnEarn App would get benefits, even
                                though you had referred them first. The first link used to install the
                                app by your friend would be considered for the referral FitnEarn Coins.
                                FitnEarn reserves all rights to change the amounts conferred under the
                                Refer and Earn program at any point in time.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Your unique referral links/code should only be used for personal
                                                and non-commercial purposes. You cannot distribute/publish your
                                                unique referral link/code where there is no reasonable basis for
                                                believing that most of the recipients are personal friends (such
                                                as coupon websites, forums, Wikipedia etc.).
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Users can refer to any number of people.FitnEarn Coins can only
                                                be earned through the use of FitnEarn App or Website only.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                Depending on the coins associated to the particular exercise you
                                                complete from Mood-board Challenges, Workout Plans Exercises,
                                                Step, Exercise and Meditation Challenges Exercises as on date, a
                                                certain amount of FitnEarn Coins will be credited to the users
                                                FitnEarn account. FitnEarn Coins earned by a user will be
                                                credited to the user account within the next 24 hours.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FitnEarn Coins cannot be transferred to your bank account. Additionally,
                                FitnEarn Coins across multiple accounts cannot be combined into a single
                                account. FitnEarn Coins cannot be exchanged for any currency outside
                                FitnEarn. Users can exchange the FitnEarn coins to FitnEarn Cashback
                                Gift Rewards after reaching milestones time to time. The gift cashback
                                rewards will be provided to the user within 3-5 business days after
                                reaching a particular FitnEarn Coin milestone and submission of Cashback
                                Gift Card form on FitnEarn App/Website. The exchange/conversion rate of
                                FitnEarn coins with FitnEarn Cashback Gift card is solely at discretion
                                of FitnEarn. We value everyone effort and thus reward user on completion
                                of exercises and activities to keep them motivated on the path of
                                fitness and overall well-being.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                No two offers or discounts available can be clubbed together unless
                                specified otherwise.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                    FITNEARN COINS EARNED AS A RESULT OF FRAUDULENT ACTIVITIES WILL BE
                                    REVOKED AND DEEMED INVALID.&nbsp;
                                </strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <em>
                                    In case the FitnEarn Coins are not credited to your account, please
                                    write to “
                                </em>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <em>
                                    <strong>help-support@fitnearn.com</strong>
                                </em>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <em>”.</em>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>Redemption of FitnEarn Coins</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                For the purpose of redemption,&nbsp;
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>10 (Ten) FitnEarn Coin&nbsp;</strong>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                will be equal in value to&nbsp;
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>Rs.1 (Rupee One)</strong>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                &nbsp;as the base value initially, which is subject to change based on
                                the contribution of the users which will affect the exchange rate on the
                                FitnEarn App or Website. However, FitnEarn reserves the right to modify
                                the formula used for calculating the exchange rate/value of the FitnEarn
                                Coins.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FitnEarn Coins can be redeemed on purchase of any product or service
                                from the FitnEarn Store available on FitnEarn App or Website.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                When you make a purchase using FitnEarn Coins balance, in the event of a
                                cancellation request of the purchase, the FitnEarn Coins used will not
                                be credited back to your FitnEarn Coin balance.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                On redemption, the FitnEarn Coins so redeemed would be automatically
                                subtracted from the accumulated FitnEarn Coins in your FitnEarn Account.
                                Similarly, on exchange of FitnEarn coins to FitnEarn Cashback Gift
                                Rewards and the cashback rewards have been redeemed or utilised by the
                                user it would&nbsp;
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                automatically subtract&nbsp;
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                from the accumulated FitnEarn Cashback Gift Rewards.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>FitnEarn Store</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                All Products listed for sale at the FitnEarn Store are subject to
                                availability and restrictions at the time of purchase. The Products
                                listed in the FitnEarn are subject to change without any prior notice.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                Products listed on FitnEarn Store are meant for end-consumers only (and
                                not resale). We may refuse to service your order if we suspect that you
                                are buying Products for resale.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FitnEarn may cancel or refuse your order, or limit the quantity of
                                Products stated in your order, at its sole discretion. FitnEarn may also
                                require further information from you before confirming or processing
                                your order.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                The price of products is displayed in Indian Rupees. It may exclude
                                delivery charges, postage and handling charges, conveyance charges,
                                and/or applicable taxes. However, the total price of Products (including
                                voluntary and involuntary charges) will be shown to you and your consent
                                will be taken before confirming any order.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                We endeavour to deliver purchased Products to you as soon as possible,
                                as per the indicative delivery schedule shown on our Mobile
                                Application/Website.&nbsp;
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                However, the actual delivery times may vary, and depend on many factors
                                beyond our control (like area/PIN code of delivery, processing of
                                shipments by our logistics vendors, availability of transport services,
                                or any other factors not within our control). We assume no liability if
                                Products are delivered after the indicative delivery schedule.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                Title in Products bought from the FitnEarn Store will pass to you upon
                                delivery of the Products to our transport carrier. However, the risk of
                                loss or damage to Products will pass to you upon delivery of the
                                Products at your submitted address.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FitnEarn does not accept liability for damage to persons or property
                                whatsoever caused in relation to Products bought by FitnEarn Users
                                through FitnEarn Store.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FitnEarn will not be liable or responsible for the Products offered
                                through the FitnEarn Store and FitnEarn gives no warranty (whether
                                express or implied) or representation either express or implied with
                                respect to type, quality or fitness of goods acquired or their
                                suitability for any purpose, whatsoever.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                If a Product delivered to you has obvious damage upon receipt, or is not
                                the Product you ordered, you can request an exchange/replacement from
                                FitnEarn, as long as you make your request within 7 working days of the
                                date of receipt of the Product. We may contact you to confirm Product
                                damage or to identify the Product delivered to you, before confirming an
                                exchange/replacement. Please ensure that you retain Products in original
                                condition, the Product packaging, price tags, supporting accessories and
                                information booklets/brochure, to allow us to process an
                                exchange/replacement.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                You are responsible for determining if the Product you purchase is
                                compatible with other equipment (if such other equipment is required).
                                You acknowledge that an absence of compatibility is not a defect in the
                                Product permitting you to exchange/return it.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                Any images displayed on the FitnEarn Store for Products are for
                                illustrative purposes&nbsp;
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                only. Characteristics of actual Products may vary.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>FEES AND PAYMENT</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                We accept the following forms of payment:
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>Credit Card</span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>Debit Card</span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>Net Banking</span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>Mobile Wallet</span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>UPI</span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                You may be required to purchase or pay a fee to access some of our
                                services. You agree to provide current, complete, and accurate purchase
                                and account information for all purchases made via the Website/Mobile
                                Application. You further agree to update your account promptly and
                                payment information, including email address, payment method, and
                                payment card expiration date, so that we can complete your transactions
                                and contact you as needed. We bill you through an online billing account
                                for purchases made via the Website/Mobile Application. GST will be added
                                to the price of purchases as deemed required by us. We may change prices
                                at any time. All payments shall be in Indian National Rupees.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                You agree to pay all charges or fees at the prices then in effect for
                                your purchases and you authorise us to charge your chosen payment
                                provider for any such amounts upon making your purchase.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                We reserve the right to correct any errors or mistakes in pricing, even
                                if we have already requested or received payment. We also reserve the
                                right to refuse any order placed through the Website/Mobile Application.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                We use advanced encryption technology, consistent with industry
                                practice, to protect your payment information. All payments on the
                                Platform are processed through secure and trusted payment gateways,
                                managed by leading banks or service providers. We understand that banks
                                use the 3D secure password service for online transactions, adding
                                another security layer.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                Any accepted refunds/chargebacks will be routed back to the payer using
                                the same mechanism by which payment was made. For example, an accepted
                                refund for a payment made through a debit card, will be routed back to
                                the debit card holder’s bank account.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <u>
                                    <strong>Subscription (To access certain&nbsp;</strong>
                                </u>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontStyle: "normal" }}>
                                    <u>
                                        <strong>
                                            <span style={{ background: "transparent" }}>premium&nbsp;</span>
                                        </strong>
                                    </u>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <u>
                                    <strong>features by User)</strong>
                                </u>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                : Subscription is automatically renewed at the end of each Subscription
                                Period unless you cancel it at least 24 hours before the expiry of the
                                current Subscription Period. If you do not cancel the Subscription at
                                least 24 hours before the expiry of the current Subscription Period, the
                                Subscription fees for the next Subscription. Period will be taken during
                                the 24 hours before the expiry of the current Subscription Period.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                If a Subscription fee cannot be taken due to the absence of monetary
                                funds, invalidity of credit card or for any other reasons, the
                                Subscription will not automatically end. The Subscription will
                                automatically restart as soon as valid payment details are provided.
                                Cancellation of a Subscription can only be done at your manual request.
                                Please see the section below "
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>How can I cancel a Subscription</strong>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>" for further details.</span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>CANCELLATION</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                You can manage and cancel your subscriptions at any time in the
                                                Account Settings of your device. For iOS subscriptions, please
                                                see Apple's support page at&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <em>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                https://support.apple.com
                                            </span>
                                        </span>
                                    </em>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                . For Google Play subscriptions, please see Google Play's
                                                support page at&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <em>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                https://support.google.com/googleplay
                                            </span>
                                        </span>
                                    </em>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>.</span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                FitnEarn may terminate your account at any time without notice
                                                if it believes that you have violated this terms of use. Upon
                                                such termination, you will not be entitled to any refund for
                                                purchases.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                All payment, billing and transaction processes are handled by
                                                the relevant third-party distributor or platform such as Apple's
                                                App Store or Google's Google Play. For any payment related
                                                issues, you may contact such distributors directly. For any
                                                other issues, please write to us at&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                help-support@fitnearn.com
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>.</span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                There are no refunds on subscription after the&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                7-Day free trial
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                . Users can stop the subscription for subsequent months without
                                                penalties.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>ECOMMERCE TERMS</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                If a product delivered to you has obvious damage upon receipt,
                                                or is not the Product you ordered, you can request an
                                                exchange/replacement from FitnEarn, as long as you make your
                                                request within 7 working days of the date of receipt of the
                                                Product. We may contact you to confirm Product damage or to
                                                identify the Product delivered to you, before confirming an
                                                exchange/replacement. Please ensure that you retain Products in
                                                original condition, the Product packaging, price tags,
                                                supporting accessories and information booklets/brochure, to
                                                allow us to process an exchange/replacement. However, you may
                                                have to bear shipping charges, packaging charges, and/or
                                                applicable taxes, in respect of exchanged/replaced Products.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                If you are unsatisfied with our services, or would like to
                                                request a return/refund, please email us at&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                help-support@fitnearn.com
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                &nbsp;or call us at&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>+91-8630222654</span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>.&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                IN ADDITION TO TERMS AND CONDITIONS STIPULATED FOR ECOMMERCE
                                                TERMS KINDLY REFER THE RETURN AND REFUND POLICY OF FITNEARN
                                                AVAILABLE ON APP/WEBSITE.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>COMMUNITY STANDARDS AND CONDUCT GUIDELINES</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                You may not access or use the Services for any purpose other
                                                than that for which we make the Services available. The Services
                                                may not be used concerning any commercial endeavours except
                                                those that are specifically endorsed or approved by us.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                As a user of the FitnEarn Services, you adhere and agree not to:
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                1.Systematically retrieve data or other content from the
                                                Website/Mobile Application to create or compile, directly or
                                                indirectly, a collection, compilation, database, or directory
                                                without written permission from us.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                2.Make any unauthorized use of the Website/Mobile Application,
                                                including collecting usernames and/or email addresses of users
                                                by electronic or other means for the purpose of sending
                                                unsolicited email, or creating user accounts by automated means
                                                or under false pretences.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                3.Use a buying agent or purchasing agent to make purchases on
                                                the Website/Mobile Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                4.Use the Website/Mobile Application to advertise or offer to
                                                sell goods and services.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                5.Circumvent, disable, or otherwise interfere with
                                                security-related features of the Website/Mobile Application,
                                                including features that prevent or restrict the use or copying
                                                of any Content or enforce limitations on the use of the
                                                Website/Mobile Application and/or the Content contained therein.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                6.Engage in unauthorized framing of or linking to the
                                                Website/Mobile Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                7.Trick, defraud, or mislead us and other users, especially in
                                                any attempt to learn sensitive account information such as user
                                                passwords.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                8.Trick, defraud, or mislead us by earning Fitnearn coins with
                                                falsified methods or by using system bots, software etc.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                9.Make improper use of our support services or submit false
                                                reports of abuse or misconduct.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                10.Engage in any automated use of the system, such as using
                                                scripts to send comments or messages, or using any data mining,
                                                robots, or similar data gathering and extraction tools.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                11.Interfere with, disrupt, or create an undue burden on the
                                                Website/Mobile Application or the networks or services connected
                                                to the Website/Mobile Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                12.Attempt to impersonate another user or person or use the
                                                username of another user.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                13.Sell or otherwise transfer your profile.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                14.Use any information obtained from the Website/Mobile
                                                Application in order to harass, abuse, or harm another person.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                15.“Stalk” or otherwise harass another user or employee of the
                                                Services.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                16.Access or attempt to access another user’s account without
                                                his or her consent.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                17.Use the Website/Mobile Application as part of any effort to
                                                compete with us or otherwise use the Website/Mobile Application
                                                and/or the Content for any revenue-generating endeavour or
                                                commercial enterprise.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                18.Decipher, decompile, disassemble, or reverse engineer any of
                                                the software comprising or in anyway making up a part of the
                                                Website/Mobile Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                19.Attempt to bypass any measures of the Website/Mobile
                                                Application designed to prevent or restrict access to the
                                                Website/Mobile Application, or any portion of the Website/Mobile
                                                Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                20.Harass, annoy, intimidate, or threaten any of our employees
                                                or agents engaged in providing any portion of the Website/Mobile
                                                Application to you.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                21.Delete the copyright or other proprietary rights notice from
                                                any Content.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                22.Copy or adapt the Website/Mobile Application software,
                                                including but not limited to Flash, PHP, HTML, JavaScript,
                                                Angular or any other code.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                23. Upload or transmit (or attempt to upload or to transmit)
                                                viruses, Trojan horses, or other material, including excessive
                                                use of capital letters and spamming (continuous posting of
                                                repetitive text), that interferes with any party’s uninterrupted
                                                use and enjoyment of the Website/Mobile Application or modifies,
                                                impairs, disrupts, alters, or interferes with the use, features,
                                                functions, operation, or maintenance of the Website/Mobile
                                                Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                24.Upload or transmit (or attempt to upload or to transmit) any
                                                material that acts as a passive or active information collection
                                                or transmission mechanism, including without limitation, clear
                                                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                                                cookies, or other similar devices (sometimes referred to as
                                                “spyware” or “passive collection mechanisms” or “pcms”).
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                25.Except as may be the result of a standard search engine or
                                                Internet browser usage, use, launch, develop, or distribute any
                                                automated system, including without limitation, any spider,
                                                robot, cheat utility, scraper, or offline reader that accesses
                                                the Website/Mobile Application, or using or launching any
                                                unauthorized script or other software.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                26.Disparage, tarnish, or otherwise harm, in our opinion, us
                                                and/or the Website/Mobile Application.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                27.Use the Website/Mobile Application in a manner inconsistent
                                                with any applicable laws or regulations.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                28.Your privilege to use the Services depends on your compliance
                                                with the community standards and conduct guidelines set forth
                                                above. We may revoke your privileges to use all or a portion of
                                                the Services and/or take any other appropriate measures to
                                                enforce these community standards and conduct guidelines if
                                                violations are brought to our attention. Further, if you fail to
                                                adhere to our community standards and conduct guidelines, or any
                                                part of these Terms &amp; Conditions, we may terminate, in our
                                                sole discretion, your use of, or participation in, any Public
                                                Forum or the Services. Any violation of this section may subject
                                                you to civil and/or criminal liability.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0in",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                YOU AGREE AND UNDERSTAND THAT YOU MAY BE HELD LEGALLY
                                                RESPONSIBLE FOR DAMAGES SUFFERED BY OTHER MEMBERS OR THIRD
                                                PARTIES AS THE RESULT OF YOUR REMARKS, INFORMATION, FEEDBACK OR
                                                OTHER CONTENT POSTED OR MADE AVAILABLE ON THE SERVICES
                                                (INCLUDING ANY FORUM) THAT IS DEEMED DEFAMATORY OR OTHERWISE
                                                LEGALLY ACTIONABLE. UNDER SECTION 79 OF THE INFORMATION
                                                TECHNOLOGY AMENDMENT ACT, 2008,&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>FITNEARN</span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                &nbsp;IS NOT LEGALLY RESPONSIBLE, NOR CAN IT BE HELD LIABLE FOR
                                                DAMAGES OF ANY KIND, ARISING OUT OF OR IN CONNECTION TO ANY
                                                DEFAMATORY OR OTHERWISE LEGALLY ACTIONABLE REMARKS, INFORMATION,
                                                FEEDBACK OR OTHER CONTENT POSTED OR MADE AVAILABLE ON THE
                                                SERVICES.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <strong>
                                            <span style={{ background: "transparent" }}>
                                                ANTI HARRASMENT POLICY
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "150%",
                                marginBottom: "0in",
                                textDecoration: "none"
                            }}
                        >
                            <br />
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn is committed to creating a safe and inclusive environment for all
                            users. Harassment in any form is strictly prohibited on our platform, to
                            ensure a welcoming and positive experience for everyone in our community.
                            This policy applies equally to all users, protecting both public figures
                            and private individuals from harassment and abuse. We encourage the use of
                            FitnEarn's reporting tools to help safeguard our community against such
                            behaviour. Violations of this policy will lead to immediate exclusion from
                            the FitnEarn community and termination of the offender's account.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>
                                <strong>Prohibited Behaviours Include:</strong>
                            </u>
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Persistently contacting someone who has made it clear they do not wish
                                    to communicate.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Sending unsolicited messages to a large number of users.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Engaging in or promoting:
                                </p>
                            </li>
                        </ol>
                        <ul>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Use of profane language targeted at individuals or groups.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Threats of death, serious illness, injury, or causing physical harm.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Bullying, trolling, or any form of harassment intended to intimidate,
                                    upset, or embarrass.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Spreading falsehoods about victims of tragic events or their families
                                    to discredit or harm their reputation.
                                </p>
                            </li>
                        </ul>
                        <ol start={4}>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Group messages that harass, intimidate, or aim to distress.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Maliciously targeting individuals, including public figures,
                                    especially those who have been victims of sexual assault or
                                    exploitation by:
                                </p>
                            </li>
                        </ol>
                        <ul>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Threatening violence to silence or intimidate participants in
                                    any&nbsp;
                                    <br />
                                    &nbsp;discussion.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Encouraging self-harm or suicide.
                                </p>
                            </li>
                        </ul>
                        <ol start={6}>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0.17in"
                                    }}
                                >
                                    Falsely accusing individuals, including victims or survivors of
                                    tragedies, of lying or fabricating their experiences.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0.17in"
                                    }}
                                >
                                    Directing harassment towards minors, including but not limited to:
                                </p>
                            </li>
                        </ol>
                        <ul>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Suggestive claims regarding sexual activities or diseases.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Altering content to include violent imagery.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Threats of serious illness, death, or violence.
                                </p>
                            </li>
                        </ul>
                        <ol start={8}>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Creating content specifically designed to demean, insult, or harm
                                    individuals through:
                                </p>
                            </li>
                        </ol>
                        <ul>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Offensive language.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Derogatory physical descriptions.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Accusations of blasphemy.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Expressions of contempt or disgust.
                                </p>
                            </li>
                        </ul>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>
                                <strong>Enforcement</strong>
                            </u>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn takes these policies seriously and will take immediate action
                            against those found in breach. Our goal is to maintain a safe space for
                            dialogue, growth, and connection within the fitness and wellness
                            community. We reserve the right to remove any content that violates these
                            guidelines and to take appropriate measures, including account
                            termination, against those responsible for such violations.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>FITNEARN COMMUNITY/SOCIAL CONTRIBUTIONS POLICY</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn provides interactive spaces such as forums, blogs, chat rooms,
                            groups, and messaging features (collectively, “Community/Social Features”)
                            as part of our Services. These are designed to allow you to upload, share,
                            post, or otherwise exchange content and information with other users. All
                            interactions within these Community Features are public and not private.
                            You bear sole responsibility for the content you share ("User
                            Contributions") and the consequences thereof.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <u>Contributions on the Platform</u>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Through the FitnEarn platform, you are encouraged to engage, share, and
                            contribute by posting, transmitting, or submitting various forms of
                            content, including texts, videos, photos, graphics, and personal insights
                            (collectively, "<strong>Contributions</strong>"). These Contributions may
                            be accessible to other platform users and possibly through external sites.
                            By sharing Contributions, you accept that they are considered
                            non-confidential and non-proprietary.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By providing contributions, you affirm that:
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Your Contributions do not infringe upon any rights, such as copyright,
                                    patents, trademarks, trade secrets, or moral rights of any third
                                    party.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You possess or have obtained all necessary permissions, rights, and
                                    consents to allow your Contributions to be used on the FitnEarn
                                    platform as envisioned by these terms.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    All individuals identifiable in your Contributions have given their
                                    consent for their likeness to be used in the manner intended by the
                                    platform.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    The information and content in your Contributions are accurate and
                                    truthful.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Your Contributions are not promotional spam, unsolicited schemes, or
                                    any form of solicitation.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You refrain from posting content that is obscene, offensive,
                                    harassing, or otherwise objectionable as deemed by FitnEarn.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You do not engage in behaviour or share content that demeans, mocks,
                                    or threatens others.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Your Contributions do not promote violence or illegal acts, nor do
                                    they incite harm against individuals or entities.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You ensure your Contributions comply with all relevant laws and do not
                                    breach any regulations or rules.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Your Contributions respect the privacy and publicity rights of others
                                    and do not exploit individuals under the age of 18 in anyway.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You avoid sharing content that could be considered child pornography
                                    or that harms minors in anyway.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Your Contributions are free from discriminatory or offensive remarks
                                    related to race, national origin, gender, sexual preference, or
                                    physical disabilities.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You ensure your Contributions do not contravene these Terms and
                                    Conditions or any applicable laws.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <u>Consequences of Violations</u>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Failure to adhere to these guidelines may lead to actions including but
                            not limited to the removal of your contributions, suspension of your
                            access to social/community Features, or termination of your FitnEarn
                            account. FitnEarn reserves the right to enforce these policies at its
                            discretion to maintain a respectful and safe community environment.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                FITNEARN DOES NOT REPRESENT, WARRANT OR GUARANTEE THE ACCURACY OF
                                INFORMATION POSTED BY ANY USER, AND HEREBY DISCLAIMS ALL RESPONSIBILITY
                                AND LIABILITY FOR ANY INFORMATION PROVIDED BY USER IN CONNECTION WITH
                                THEIR USE OF THE SERVICES.
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>FITNEARN MOBILE APPLICATION LICENSE AGREEMENT</strong>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>License for Use</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By downloading the FitnEarn mobile application, you are granted a
                            revocable, non-exclusive, non-transferable, limited license to install and
                            operate the application on mobile devices that you own or control. This
                            license is granted solely for your personal, non-commercial use, strictly
                            in accordance with the terms outlined in this agreement.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>Prohibited Actions</strong>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            As a user of the FitnEarn application, you agree not to:
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Decompile, reverse engineer, disassemble, or attempt to discover the
                                    source code or decrypt the application.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Modify, adapt, enhance, translate, or create derivative works from the
                                    application without prior written permission.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Violate any laws, regulations, or rules in your use of the
                                    application.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Remove, conceal, or alter any proprietary notices, including copyright
                                    and trademark notices, related to the application.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Utilize the application for any revenue-generating or commercial
                                    activities not expressly permitted by this license.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Distribute the application across a network where it could be used by
                                    multiple devices simultaneously.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Develop, produce, or distribute any service or product that competes
                                    with or replaces the FitnEarn application.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Use the application to send automated queries or unsolicited
                                    commercial communications.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Employ any proprietary information, interfaces, or other intellectual
                                    property of FitnEarn in the creation of any software or hardware
                                    accessories for use with the application.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>Use on Apple and Android Devices</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            This license extends to the use of the FitnEarn application obtained via
                            the Apple App Store or Google Play Store ("App Distributors") under these
                            conditions:
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The license granted hereunder is limited to a non-transferable license to
                            use the application on Apple iOS or Android-operated devices, according to
                            the usage rules specified in the service terms of the App Distributors.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn is responsible for all maintenance and support services for the
                            mobile application as required by these terms or applicable laws. Each App
                            Distributor is not obligated to offer maintenance or support services for
                            the application.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Should the application fail to comply with any applicable warranty, you
                            may inform the respective App Distributor, and they may refund the
                            purchase price of the application per their policies. App Distributors
                            have no other warranty obligations concerning the application.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            You confirm that:
                        </p>
                        <ol type="i">
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You are not located in a country under embargo by the Indian
                                    government or labelled as a "terrorist supporting" country.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You are not on any Indian government list of banned or restricted
                                    parties.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You agree to comply with all third-party terms when using the
                                    application. For example, using the application must not breach any
                                    VoIP service agreement.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    You acknowledge that App Distributors are third-party beneficiaries of
                                    this license agreement and have the right to enforce these terms
                                    against you.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            BY USING THE FITNEARN MOBILE APPLICATION, YOU ACCEPT AND AGREE TO BE BOUND
                            BY THE TERMS OF THIS LICENSE AGREEMENT.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>FITNEARN SOCIAL MEDIA INTEGRATION POLICY</strong>
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>Integration with Third-Party Social Media Accounts</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn offers enhanced functionality by allowing you to link your
                            account with external third-party service providers (referred to as
                            "Third-Party Accounts"). This can be done in two ways:
                        </p>
                        <ul>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    By directly providing your Third-Party Account credentials to FitnEarn
                                    through our platform.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    By authorising FitnEarn to access your Third-Party Accounts according
                                    to the terms and conditions governing those accounts.
                                </p>
                            </li>
                        </ul>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By linking your Third-Party Accounts, you affirm that you have the right
                            to share your login details with us and allow us access without violating
                            any terms of those accounts or incurring additional fees or limitations
                            from the third-party providers.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Access and Use of Social Network Content&nbsp;</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>Upon linking your Third-Party Accounts</u>:
                        </p>
                        <ol type="i">
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    FitnEarn may access, display, and store content you've saved on your
                                    Third-Party Accounts (referred to as "Social Network Content") to
                                    enhance your experience on our platform. This includes but is not
                                    limited to friend lists and shared content.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    We might also receive and transmit additional information from your
                                    Third-Party Accounts, as notified by those services. The visibility of
                                    your personal information from Third-Party Accounts on FitnEarn is
                                    subject to your privacy settings on those external platforms.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Should a Third-Party Account become inaccessible or our access be
                                    revoked, the associated Social Network Content may no longer be
                                    available on FitnEarn. However, you can manage or sever the connection
                                    between your FitnEarn account and Third-Party Accounts at any time.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Important Considerations</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Your relationship with any Third-Party Account providers is governed
                            solely by your agreement with them. FitnEarn does not review Social
                            Network Content for accuracy, legality, or adherence to copyright laws,
                            and is not responsible for the content shared through these integrations.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            You agree that FitnEarn may access your contact lists or email address
                            books linked to Third-Party Accounts solely to identify and notify you of
                            contacts who are also users of FitnEarn. To disconnect your FitnEarn
                            account from Third-Party Accounts, you may use the provided contact
                            methods or adjust your account settings accordingly. We aim to remove any
                            data received through Third-Party Accounts from our servers upon
                            disconnection, except for data such as usernames and profile pictures that
                            have become associated with your FitnEarn account.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By utilizing the social media integration features of FitnEarn, you
                            acknowledge and consent to these terms, enhancing your connectivity and
                            experience within our platform.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>THIRD PARTY WEBSITES AND CONTENT</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The FitnEarn platform may feature links to external websites ("Third-Party
                            Websites") and include or offer access to content such as articles,
                            photos, text, graphics, music, videos, apps, and other materials created
                            by third parties ("Third-Party Content"). Please note, FitnEarn does not
                            conduct thorough reviews, monitoring, or checks on the accuracy,
                            suitability, or completeness of any Third-Party Websites or Third-Party
                            Content. As such, we cannot be held accountable for the content, quality,
                            or policies of any Third-Party Websites or Third-Party Content accessed
                            via our platform.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Disclaimer</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The presence of links to Third-Party Websites or the inclusion of
                            Third-Party Content on the FitnEarn platform does not imply our
                            endorsement or approval. Deciding to engage with any Third-Party Websites
                            or Third-Party Content is at your discretion and risk. Once you leave the
                            FitnEarn platform, our Terms and Conditions no longer apply, and you are
                            advised to review the terms, privacy policies, and practices of any
                            Third-Party Websites you visit or from which you download or use content.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Purchases and Interactions with Third-Party Websites</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Any transactions or interactions you engage in with Third-Party Websites,
                            including purchases, are strictly between you and the respective third
                            party. FitnEarn assumes no responsibility for such transactions or
                            interactions. It is your responsibility to ensure that any products,
                            services, or information obtained from Third-Party Websites meet your
                            expectations and comply with the relevant terms of sale or service.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>Indemnity</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            You agree to absolve FitnEarn from any responsibility for losses or
                            damages you may incur due to, your use of Third-Party Content or
                            interactions with Third-Party Websites. This includes holding FitnEarn
                            harmless against any claims related to the products or services offered or
                            advertised on these external sites.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            BY USING THE FITNEARN PLATFORM, YOU ACKNOWLEDGE AND ACCEPT THIS POLICY
                            REGARDING THIRD-PARTY WEBSITES AND THIRD-PARTY CONTENT, RECOGNIZING THAT
                            YOUR USE OF SUCH EXTERNAL RESOURCES IS GOVERNED BY THEIR RESPECTIVE TERMS
                            AND NOT BY FITNEARN’S POLICIES.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>FITNEARN PLATFORM MANAGEMENT AND OPERATIONS POLICY</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Platform Oversight and Enforcement</strong>
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    FitnEarn asserts the right, though not the obligation, to:
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Actively monitor the platform for any breaches of our Terms and
                                    Conditions.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Undertake suitable legal measures against anyone found infringing
                                    these terms or the law, which may include reporting offenders to law
                                    enforcement authorities.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    At our discretion, limit, restrict, or remove user contributions that
                                    violate our guidelines or pose a burden on our systems, without prior
                                    notice or liability.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Manage and curate the content on the platform to ensure it aligns with
                                    our operational standards and does not overwhelm our technological
                                    capacities.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Operate the platform in a way that safeguards our rights and
                                    properties while ensuring its smooth and efficient function.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Modifications, Pauses, and Service Interruptions</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn reserves the complete authority to:
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Alter, omit, or update any content on the platform whenever necessary,
                                    without prior notification. Our commitment to maintaining current
                                    information does not imply an obligation to update any content.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Modify, halt, or completely discontinue any aspect of the platform at
                                    our discretion, without advance warning. We bear no responsibility for
                                    any impact such changes may have on users.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Not guarantee uninterrupted or error-free access to the platform.
                                    Maintenance, technical issues, or unforeseen problems may lead to
                                    temporary unavailability or disruptions in service.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Adjust, pause, or cease operations of the platform without notice for
                                    any reason deemed necessary. We are not liable for any inconvenience,
                                    loss, or damage resulting from such interruptions.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By using FitnEarn, you acknowledge and accept these terms, understanding
                            that platform availability and content are subject to change and may be
                            temporarily inaccessible due to maintenance or technical difficulties.
                            These policies are in place to ensure the optimal functioning of FitnEarn
                            and to protect the interests of our community.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>PRIVACY POLICY</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            We care about data privacy and security. Please review our Privacy Policy
                            at:&nbsp;
                            <span style={{ background: "transparent" }}>
                                https://www.fitnearn.com/privacy-policy
                            </span>
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>FITNEARN INTELLECTUAL PROPERTY RIGHTS POLICY</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn is committed to upholding the intellectual property rights of
                            others and expects its users to do the same. Should you suspect that your
                            intellectual property rights have been infringed upon by material
                            accessible on or through our platform, we urge you to inform us promptly.
                            Please direct your information of alleged infringement to us using the
                            designated contact details provided on our platform (referred to here as
                            "IP NOTICE").
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Upon receipt, we will forward a copy of your notice to the individual
                            responsible for the content in question. It's important to note that under
                            Indian Copyright laws, individuals making false claims of copyright
                            infringement in their IP Notice may face legal consequences, including
                            potential liability for damages. Therefore, if there's any doubt regarding
                            the infringement of your copyright by material on our platform, consulting
                            with legal counsel before submitting a IP Notice is advisable.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn reserves the right to terminate the accounts of users who are
                            found to be repeat infringers of intellectual property rights.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>TERMINATION</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The terms outlined herein will remain effective and binding for as long as
                            you utilize the FitnEarn Website or Mobile Application.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn reserves the unrestricted right to terminate access or use of the
                            Website/Mobile Application for any user, at our sole discretion, without
                            any prior notice or liability. This includes the right to block specific
                            IP addresses. Termination may be due to any breach of the representations,
                            warranties, or covenants made in these Terms and Conditions, violation of
                            any laws or regulations, or for no specific reason at all.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Should your account be terminated or suspended, regardless of the reasons,
                            you are expressly forbidden from creating a new account under your own
                            name, someone else's name, or any fictitious name. This prohibition
                            extends to creating accounts on behalf of others.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Furthermore, FitnEarn maintains the right to pursue legal actions,
                            including civil, criminal, and injunctive remedies, against anyone whose
                            actions necessitate such responses.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>GOVERNING LAWS</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The Terms and Conditions governing your engagement with the FitnEarn
                            Website or Mobile Application are exclusively subject to the laws of
                            India. Should any disputes arise under this Agreement, they will be
                            resolved in the jurisdiction of the courts situated in Haridwar District.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            To pursue a legal claim related to the services provided by FitnEarn,
                            users must initiate such action within thirty (30) days from the date:
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    The claim or cause of action first emerged; or
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    The user first became aware of the circumstances leading to the claim,
                                    whichever occurs later.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Failure to initiate a claim within this timeframe will result in the
                            permanent waiver of the right to pursue that cause of action.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>FITNEARN DISPUTE RESOLUTION FRAMEWORK</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Amicable Resolution Attempt</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            For any disputes, controversies, or claims arising from or related to this
                            Agreement or any services provided by the FitnEarn platform, including
                            questions about its existence, validity, or termination, both parties will
                            endeavour to resolve the matter amicably. If a resolution cannot be
                            reached within 30 days of notifying the other party of the dispute, either
                            party has the option to proceed to binding arbitration.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Binding Arbitration Process</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            In line with the Indian Arbitration &amp; Conciliation Act, 1996,
                            unresolved disputes will be submitted to binding arbitration. This process
                            will address the dispute on an individual basis, without consolidation
                            with claims of other parties. A sole arbitrator, appointed according to
                            the Act, will oversee the arbitration, which will take place in Pune, with
                            English as the arbitration language. Both parties retain the right to seek
                            interim relief from a Pune jurisdiction court to protect their rights or
                            property while arbitration is pending. The confidentiality of the
                            arbitration process is paramount, with disclosures only permitted as
                            required by law or for enforcing the arbitration award. The costs of
                            arbitration, including administrative fees and legal expenses, will be
                            shared equally between the parties.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Arbitration Limitations</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Arbitration will be limited strictly to the dispute between the involved
                            parties, under the following conditions:
                        </p>
                        <ol type="i">
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    No arbitration will be merged with other proceedings.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    There is no provision for the dispute to be arbitrated as a class
                                    action or to employ class action procedures.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Disputes cannot be represented on behalf of the general public or any
                                    group of persons not directly involved in the arbitration.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>Exceptions to Arbitration</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Certain disputes are exempt from the arbitration agreement, specifically:
                        </p>
                        <ol type="i">
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Issues aiming to enforce or protect intellectual property rights.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Cases involving allegations of theft, piracy, privacy breaches, or
                                    unauthorized use.
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Claims seeking injunctive relief.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            In situations where arbitration is deemed inapplicable or unenforceable,
                            such disputes will be resolved in the competent courts within the
                            jurisdiction of Pune, to which both parties agree to submit.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            THIS DISPUTE RESOLUTION POLICY WILL REMAIN EFFECTIVE EVEN AFTER THE
                            TERMINATION OF THE AGREEMENT.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>CONTENT CORRECTIONS POLICY</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            On occasion, the FitnEarn Website or Mobile Application may display
                            content that includes typographical mistakes, inaccuracies, or missing
                            information related to service descriptions, pricing, availability, and
                            more. We are committed to maintaining accurate and up-to-date information,
                            and as such, we hold the authority to rectify any errors, inaccuracies, or
                            omissions. Furthermore, we may modify or refresh content at any moment
                            without issuing a prior announcement.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>FITNEARN USAGE DISCLAIMER</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The FitnEarn Website and Mobile Application are made available to you on
                            an "as-is" and "as-available" basis. Your decision to access and use our
                            platform and services is at your own risk. We expressly disclaim, to the
                            maximum extent allowed by law, all warranties, whether express or implied,
                            including but not limited to implied warranties of merchantability,
                            suitability for a specific purpose, and non-infringement.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            We do not guarantee the accuracy, reliability, or completeness of any
                            content found on the FitnEarn platform or any linked sites. FitnEarn
                            assumes no liability for any errors or inaccuracies in content, any
                            personal injury or property damage arising from your use of the platform,
                            unauthorized access to our servers, interruption or cessation of
                            communication with the platform, or any harmful components that may be
                            transmitted by third parties through our platform. Additionally, we are
                            not responsible for any loss or damage resulting from the utilization of
                            content posted, shared, or made available through the FitnEarn platform.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn does not endorse, guarantee, or assume responsibility for any
                            product or service advertised or offered by third parties through our
                            platform or any hyperlinked website, nor do we oversee any transactions
                            between you and third-party providers. When engaging in transactions or
                            using products and services found through FitnEarn or any linked sites, we
                            advise you to exercise caution and make informed decisions.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            This disclaimer serves to highlight the inherent risks associated with
                            using online platforms and to remind users to proceed with caution and
                            make use of their best judgment when interacting with content, entering
                            into transactions, or utilising services offered through the FitnEarn
                            platform or its affiliates.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>LIABILITY LIMITATIONS</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FITNEARN, ALONG WITH OWNER, EMPLOYEES, AND AGENTS, SHALL NOT BE HELD
                            ACCOUNTABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                            EXEMPLARY, OR PUNITIVE DAMAGES. THIS INCLUDES, BUT IS NOT LIMITED TO,
                            FINANCIAL LOSSES SUCH AS LOST PROFITS OR REVENUE, DATA LOSS, OR ANY OTHER
                            FORM OF DAMAGE THAT MAY ARISE FROM YOUR ENGAGEMENT WITH THE FITNEARN
                            WEBSITE OR MOBILE APPLICATION. THIS LIMITATION APPLIES REGARDLESS OF
                            WHETHER WE HAVE BEEN INFORMED OF THE POTENTIAL FOR SUCH DAMAGES.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>INDEMNIFICATION AGREEMENT</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By utilising the FitnEarn platform, you commit to defend, indemnify, and
                            absolve FitnEarn, its subsidiaries, affiliates, officers, agents,
                            partners, and employees, from any claims, liabilities, losses, damages, or
                            expenses, including reasonable attorney fees, brought forth by third
                            parties due to or arising from your actions, including but not limited to:
                        </p>
                        <ol>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    The content you contribute to the platform;
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Your interaction with and use of the FitnEarn Website or Mobile
                                    Application;
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Any violations of these Terms and Conditions;
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Breaches of the representations and warranties you've provided within
                                    these Terms and Conditions;
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Infringement upon the rights of third parties, especially concerning
                                    intellectual property rights; or
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        color: "rgb(0, 0, 0)",
                                        textAlign: "justify",
                                        background: "transparent",
                                        
                                        fontSize: 16,
                                        lineHeight: "150%",
                                        marginBottom: "0in"
                                    }}
                                >
                                    Any direct harm caused to another user of the platform that resulted
                                    from connections made through FitnEarn.
                                </p>
                            </li>
                        </ol>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Should such a situation arise, FitnEarn reserves the right to take over
                            the exclusive defence and control of any claim you are obliged to
                            indemnify against, and you must cooperate with FitnEarn’s defence strategy
                            at your own cost. FitnEarn pledges to make a reasonable effort to keep you
                            informed of any such claims, actions, or proceedings immediately upon
                            awareness.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>USER DATA POLICY</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn is committed to managing and preserving the data you share
                            through our Website or Mobile Application, which includes data necessary
                            for optimising the platform's performance and enhancing your user
                            experience. We implement encryption and conduct regular backups to
                            safeguard this data. However, the responsibility for any data you transmit
                            or generate through your activities on FitnEarn rests solely with you.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By using FitnEarn, you acknowledge and agree that FitnEarn will not be
                            held accountable for any potential loss or corruption of your data.
                            Furthermore, you relinquish any claims against FitnEarn related to such
                            data loss or corruption.
                        </p>
                        <p
                            style={{
                                color: "#000000",
                                lineHeight: "150%",
                                textAlign: "left",
                                marginBottom: "0.17in",
                                background: "transparent",
                                
                                fontSize: 16
                            }}
                        >
                            <strong>DIGITAL COMMUNICATION CONSENT</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Engaging with the FitnEarn platform, whether by browsing our site, sending
                            emails, or filling out online forms, is considered a form of electronic
                            communication. By using FitnEarn, you hereby consent to receiving
                            communications from us electronically. This includes, but is not limited
                            to, emails, notifications on our Website or Mobile Application, which
                            fulfil any legal requirements that such communications be in writing.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            By agreeing to these terms, you accept the validity of electronic
                            signatures, digital contracts, electronic orders, and other virtual
                            records used during transactions and interactions initiated or completed
                            through FitnEarn or its services.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Furthermore, you relinquish any claim to the necessity of physical
                            signatures, non-digital records, or other non-electronic methods of
                            transaction confirmation and document retention that might be mandated by
                            any law, regulation, or ordinance across various jurisdictions.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>OTHER PROVISIONS</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            The entirety of the Terms and Conditions, along with any additional
                            policies or operational guidelines provided by FitnEarn on the Website or
                            Mobile Application, form the complete and exclusive agreement between you
                            and FitnEarn. Our non-exercise or enforcement of any rights or provisions
                            within these Terms does not equate to a waiver of such rights or
                            provisions. These Terms are enforceable to the maximum extent permitted by
                            law.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn reserves the right to transfer or assign its rights and duties
                            under these Terms to any party at any given time without restriction. We
                            are not accountable for any loss, damage, or delays resulting from
                            circumstances beyond our reasonable control. Should any section, or
                            portion thereof, within these Terms be deemed invalid, illegal, or
                            unenforceable, it shall be considered detachable from the rest, which
                            shall remain in full force and effect.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            No joint venture, partnership, employment, or agency relationship exists
                            between you and FitnEarn due to, this agreement or through your use of the
                            Website or Mobile Application. These Terms shall not be interpreted
                            against the drafting party, thereby nullifying any defence you might have
                            regarding their digital format or the electronic execution of these
                            agreements.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>USER QUERY, COMPLAINTS AND GRIEVANCE REDRESSAL</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Should you require additional details about using the FitnEarn platform,
                            encounter any violations of our Terms of Use, come across any content that
                            may be deemed inappropriate, or if you have any complaints or issues
                            needing resolution, we encourage you to reach out to us directly:
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <strong>FitnEarn Contact Information</strong>
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>Address:</u>{" "}
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ textDecoration: "none" }}>
                                    <span style={{ fontStyle: "normal" }}>
                                        <span style={{ fontWeight: "normal" }}>
                                            <span style={{ background: "transparent" }}>
                                                395, Purav Deen Dayal, Old Railway Road, Veer Bhawan Nagar,
                                                Roorkee-247667, Haridwar District, Uttarakhand, India
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            &nbsp;
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>Phone:</u> +91-8630222654
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <u>Email:</u> help-support@fitnearn.com
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            Your concerns are important to us, and they will be addressed through our
                            dedicated Grievance Redressal Mechanism. To ensure we can address your
                            concerns effectively, please provide a comprehensive description of the
                            issue, including any specific details or examples. This information will
                            be invaluable in helping us understand and resolve your complaint or
                            grievance promptly.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            FitnEarn commits to resolving all grievances within a 30-day period from
                            the receipt of the complaint, adhering to the timeframes set by relevant
                            regulations. Your satisfaction and the integrity of our platform are our
                            top priorities.
                        </p>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                textAlign: "justify",
                                background: "transparent",
                                
                                fontSize: 16,
                                lineHeight: "150%",
                                marginBottom: "0.17in"
                            }}
                        >
                            <br />
                            &nbsp;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TermsAndConditions;