import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from 'axios'

import ApiUrl from "../Utils/ApiUrl";
import Footer from "../Components/Layout/Footer";
import Header from "../Components/Layout/Header";

function Blog() {
  const[blogList, setBlogList] = useState([]);
  const[tag, setTag] = useState("Latest");
  const getCall = () =>{
    console.log(ApiUrl.Blog);
    axios.get("http://127.0.0.1:3000/api/v1/blog").then(res=>{
        setBlogList(res.data.data);
      }).catch(err=>{
        console.log('error')
      })
  }
  useEffect(() => {
    getCall();
    const script = document.createElement("script");
    script.src = "js/blog.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  let filteredData
  if(tag === "latest" || tag === "popular"){
    filteredData = tag === "Latest" ? blogList.filter(item => item.tag === 'Latest') : blogList.filter(item => item.tag === 'Popular');
  }else{
    filteredData  = blogList.sort(() => Math.random() - 0.5);
    console.log(filteredData)
  }
  return (
    <>
      <section class="inner-banner position-relative">
        <Header/>
        <div class="blog-banner">
          <div class="position-relative">
            <div class="inner-txt">
              <h2>BLOGS</h2>
              <h3>MAKE YOUR BODY HEALTHY &amp; FIT</h3>
            </div>
            <img src="img/blog-banner.png" alt="" />
          </div>
          <div class="position-relative">
            <div class="inner-txt">
              <h2>BLOGS</h2>
              <h3>MAKE YOUR BODY HEALTHY &amp; FIT</h3>
            </div>
            <img src="img/blog-banner2.jpg" alt="" />
          </div>
          <div class="position-relative">
            <div class="inner-txt">
              <h2>BLOGS</h2>
              <h3>MAKE YOUR BODY HEALTHY &amp; FIT</h3>
            </div>
            <img src="img/blog-banner.png" alt="" />
          </div>
        </div>
      </section>
      <section class="blog-sec">
        <div class="container-fluid">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <Link
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={()=> setTag("latest")}
              >
                Latest
              </Link>
            </li>
            <li class="nav-item" role="presentation">
              <Link
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                onClick={() => setTag("popular")}
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Popular
              </Link>
            </li>
            <li class="nav-item" role="presentation">
              <Link
                class="nav-link"
                id="messages-tab"
                data-toggle="tab"
                href="#messages"
                role="tab"
                aria-controls="messages"
                aria-selected="false"
                onClick={()=>setTag("explore")}
              >
                Explore
              </Link>
            </li>
          </ul>
          <div class="tab-content mb-5">
            <div
              class="tab-pane active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="row blog-slider-new">
                {filteredData.length > 0 && filteredData.map((item)=>(
                  <div class="col-lg-4">
                  <div class="blog-box">
                      <Link to ={`/blog/${item._id}`}>
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img  src="img/blog-img6.png" alt="" />
                    </div>
                      </Link>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Nutrition</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> April 05,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> {item.title}Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>You deserve great health!</p>
                    </div>
                  </div>
                </div>
                ))}
                
              </div>
            </div>
            <div
              class="tab-pane"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="row blog-slider">
                <div class="col-lg-4">
                  <div class="blog-box">
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img src="img/blog-img4.jpg" alt="" />
                    </div>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Fitness</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> December 14,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> 12 Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>The Healthy life, the best</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="blog-box">
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img src="img/blog-img5.png" alt="" />
                    </div>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Lifestyle</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> July 05,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> 12 Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>Five easy and quik lunchbox</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="blog-box">
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img src="img/blog-img6.png" alt="" />
                    </div>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Nutrition</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> April 05,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> 12 Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>You deserve great health!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane"
              id="messages"
              role="tabpanel"
              aria-labelledby="messages-tab"
            >
              <div class="row blog-slider">
                <div class="col-lg-4">
                  <div class="blog-box">
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img src="img/blog-img4.jpg" alt="" />
                    </div>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Fitness</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> December 14,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> 12 Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>The Healthy life, the best</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="blog-box">
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img src="img/blog-img5.png" alt="" />
                    </div>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Lifestyle</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> July 05,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> 12 Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>Five easy and quik lunchbox</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="blog-box">
                    <div class="blog-img">
                      <div class="overlay"></div>
                      <img src="img/blog-img6.png" alt="" />
                    </div>
                    <div class="blog-head d-flex justify-content-between align-center">
                      <div class="blog-left">Nutrition</div>
                      <div class="blog-right d-flex align-center">
                        <ul>
                          <li>
                            <i class="ti-calendar"></i> April 05,2021
                          </li>
                          <li>
                            <i class="ti-book"></i> 12 Min Read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="short-content">
                      <p>You deserve great health!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="fitness">
                <div data-aos="fade-down" class="blog-heading">
                  <span>Fitness</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img7.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Fitness</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img8.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Fitness</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fitness lifetime">
                <div data-aos="fade-down" class="blog-heading">
                  <span>Lifestyle</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img9.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Lifestyle</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img10.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Lifestyle</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fitness nutrition">
                <div data-aos="fade-down" class="blog-heading">
                  <span>Nutrition</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img11.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Nutrition</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img12.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Nutrition</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fitness yoga">
                <div data-aos="fade-down" class="blog-heading">
                  <span>Yoga</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img13.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Yoga</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img14.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Yoga</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fitness cardio">
                <div data-aos="fade-down" class="blog-heading">
                  <span>Cardio</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img15.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Cardio</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-box">
                      <div class="blog-img">
                        <div class="overlay"></div>
                        <img src="img/blog-img16.png" alt="" />
                      </div>
                      <div class="blog-head d-flex justify-content-between align-center">
                        <div class="blog-left">Cardio</div>
                        <div class="blog-right d-flex align-center">
                          <ul>
                            <li>
                              <i class="ti-calendar"></i> December 14,2021
                            </li>
                            <li>
                              <i class="ti-book"></i> 12 Min Read
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="short-content">
                        <p>The Healthy life, the best</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div data-aos="fade-down" class="blog-heading">
                <span>Our Social Media Updates</span>
              </div>
              <div class="social-media">
                <h3>Twitter</h3>
                <div class="feed-img">
                  <img src="img/twitter-feed.png" alt="" />
                </div>
              </div>
              <div class="social-media">
                <h3>Instagram</h3>
                <div class="feed-img">
                  <img src="img/instagram-feed.png" alt="" />
                </div>
              </div>
              <div class="social-media">
                <h3>Youtube</h3>
                <div class="feed-img">
                  <img src="img/youtube-feed.png" alt="" />
                </div>
              </div>
              <div class="social-media">
                <h3>Facebook</h3>
                <div class="feed-img">
                  <img src="img/facebook-feed.png" alt="" />
                </div>
              </div>
              <div class="social-media linkedin">
                <h3>Linkedin</h3>
                <div class="feed-img">
                  <img src="img/linkedin-feed.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Blog;
