const Base_Url = "http://127.0.0.1:3000";
const Version = 'v1';
const ApiUrl = {
  login : `${Base_Url}/api/${Version}/user/login`,
  Coache : `${Base_Url}/api/${Version}/coache`,
  Blog : `${Base_Url}/api/${Version}/blog`,
  workoutCategory : `${Base_Url}/api/${Version}/workoutCategory`,
  workout : `${Base_Url}/api/${Version}/workout`,
  getImage : "http://localhost:3000/image/"
}

export default ApiUrl;