import axios from "axios";
import { useEffect, useState } from "react";
import ApiUrl from "../../Utils/ApiUrl";
import { Link } from "react-router-dom";

function WorkoutCategoryList({ category, setCategory }) {
  console.log("inside wrkoutcategorlist");
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    axios
      .get(ApiUrl.workoutCategory)
      .then((res) => {
        console.log(res.data.data);
        setCategoryList(res.data.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);
  return (
    <>
      {categoryList.map((item) => (
        <li class="nav-item" role="presentation">
          <Link
            class={`nav-link ${item.name === category ? 'active' : ''}`}
            id="cardiovascular-tab"
            data-toggle="tab"
            href="#cardiovascular"
            role="tab"
            aria-controls="cardiovascular"
            aria-selected="true"
            onClick={()=> setCategory(item.name)}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </>
  );
}

export default WorkoutCategoryList;
