

const ReturnAndRefundPolicy = () => {
  return (
    <div style={{ padding: "60px 100px", background: "#f5f5f5", color: "black" }}>
      <div style={{ padding: "80px", borderRadius: "4px" }}>
        <>
          <p style={{ textAlign: "center" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>RETURN AND REFUND POLICY</strong>
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>GENERAL</strong>
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              a. This website with the URL of{" "}
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.fitnearn.com/"
            >
              <span
                style={{
                  color: "#0000ff",
                  fontFamily: "Roboto, serif",
                  fontSize: "medium"
                }}
              >
                <u>www.fitnearn.com</u>
              </span>
            </a>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              {" "}
              ("Website/Site") and Mobile App by the name of FitnEarn&nbsp;&nbsp;is
              operated by Fit Earn Meditate ("We/Our/Us").FitnEarn offers a blend of
              digital services (subscriptions) and physical products (health and fitness
              items) through our mobile app and website.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              b. We are committed to providing Our customers with the highest quality
              Products and Services. However, on rare occasions, issues may arise. In
              such cases, We offer return and refund facilities in accordance with this
              Return Policy ("Policy").This Return and Refund Policy ("Policy") explains
              how we handle returns, refunds, and cancellations for our products and
              services. Please read this Policy carefully before using our services.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              c. You are advised to read Our Terms and Conditions along with this Policy
              at our Website and Mobile app.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              d. By using FitnEarn Mobile App/Website, You agree to be bound by the
              terms contained in this Policy without modification. If You do not agree
              to the terms contained in this Policy, You are advised not to transact on
              the FitnEarn app/website.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              e. We offer a 15 days' Return Policy for eligible Products*.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              f. Please read this Policy before purchasing anything on App/Website, so
              that You understand Your rights as well as what You can expect from Us if
              You are not happy with Your purchase.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>DEFINITIONS</strong>
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              a. "<strong>Business Days</strong>" - means a day that is not a Saturday,
              Sunday, public holiday, or bank holiday in India or in the state where our
              office is located.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              b. "<strong>Customer</strong>" - means a person who buys any goods or
              services for consideration but does not include a person who purchases
              such goods or services to resell them.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              c. "<strong>Date of Transaction</strong>" - means the date of purchase of
              any product or service, in accordance with the terms and conditions of the
              applicable product or service.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              d. "<strong>Website</strong>" - means this website with the URL:{" "}
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.fitnearn.com/"
            >
              <span
                style={{
                  color: "#0000ff",
                  fontFamily: "Roboto, serif",
                  fontSize: "medium"
                }}
              >
                <u>www.fitnearn.com</u>
              </span>
            </a>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              .
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              e. "<strong>Mobile App</strong>"- means mobile application by name of
              FitnEarn under Fit Earn Meditate on Google Playstore or Apple App store or
              any other app store.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>RETURNS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              a. Every effort is made to service the orders placed, as per the
              specifications and timelines mentioned with respect to the Products and
              Services. If due to any unforeseen circumstances or limitations from Our
              side, the order is not fulfilled or delivered, then such order stands
              cancelled, and the amount paid by You is refunded.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              b. We will not process a return if You have placed the order for the wrong
              size of the product or service.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              c. Return or Refund shall only be considered once the Customer concerned
              produces relevant documents and proof.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              d. Return and Refund shall be subject to testing and verifying the alleged
              issue by an authorised person on behalf of Us.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              e. If following the testing process, the product or service is found to be
              in good working order without defects, We will return the product or
              service to you, and the carriage costs of this return will be Your
              responsibility.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              f. The product or service will be eligible for replacement only if the
              same specifications are available in stock. In case the same
              specifications are out of stock, a refund shall be provided.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              g. Once qualified, the refunds are applied to the original payment option.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              h. Please be informed that the products or services purchased using the
              Cash on Delivery option will be refunded to the Bank account with the
              details provided by you to us.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              i. <strong>Subscriptions:</strong> There are no refunds after the{" "}
              <strong>7-day free trial</strong>. Users can stop the subscription for
              subsequent months without penalties.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              j. <strong>Pay as You Go:</strong> Payments for live events on website are
              non-refundable unless technical issues on our end prevent participation.
              If any technical issues are observed by any user while attending a live
              event/session on the website the same shall be informed to fitnearn with
              necessary supporting documents at our email help-support@fitnearn.com. The
              case will be reviewed by us and a refund will be initiated accordingly.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              k. <strong>Product Purchases:</strong> In case of purchase of any product
              on the website/app a standard refund policy will be applicable for
              products purchased with cash (without any Fitnearn coins) but there will
              be no refunds for products bought with FitnEarn coins.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              l. All requests for returns must be made within <strong>7 days</strong>{" "}
              from the date on which the products or services were delivered. All
              returned items must be in new and unused condition, with all original box,
              product, accessories, tags, and labels attached.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>RETURN PROCESS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              To return an item, please initiate the return request on the app/website
              from the “<strong>Contact Us</strong>” page. After requesting a return,
              place the item securely in its original packaging, and wait for the item
              to be picked up.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>ORDER NOT CONFIRMED BUT AMOUNT DEDUCTED</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              a. If the amount has been deducted and the order is not confirmed, please
              do contact Your respective bank. It takes 7 (seven) Business Days to
              reverse back the amount by the respective bank.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              b. If the issue has not been resolved within 7 (seven) Business Days, you
              can contact Our customer care support as follows:
              help-support@fitnearn.com.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>REFUNDS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              After receiving your return and inspecting the condition of your item, we
              will process your refund. We take at least seven{" "}
              <strong>(15) days</strong> from the receipt of your product to process
              your refund. We will notify you by email when your refund has been
              processed.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>RESPONSE TIME</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              a. Returns are normally processed within <strong>14 - 28</strong> Business
              Days after the completion of quality checks of the product or service
              returned.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              b. Refunds are normally processed within 7 Business Days after checking
              the veracity of the refund request.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              c. The period of refund may also depend on various banking and payment
              channels, and We will not be liable for any errors or delays in a refund
              due to banks or third-party service providers.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>EXCEPTIONS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              Notwithstanding the other provisions of this Policy, We may refuse to
              provide repair, replacement, or refund for a product or service you
              purchased if:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              i. The product or service purchased is promotional items (discounts,
              giveaways, etc.).
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              ii. The products or services are not in the same condition as when they
              were delivered.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              iii. You misused the product or service in a way that caused the problem.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              iv. You knew or were made aware of the problem(s) with the product or
              service before you purchased it.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              v. The product or service has normal wear and tear, including scratches
              and dents.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              vi. There is damage to the product other than due to a defect in materials
              or manufacturing.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              vii. There is damage to the product or service resulting from failure to
              use the Product in accordance with the instructions accompanying the
              Product or available at the app / website.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              viii. There is damage to the product or service resulting from an
              accident, flood, fire, misuse, or abuse.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              ix. There is damage resulting from unauthorised service performed, or
              damage resulting from tampering with or alterations to the Product or
              service, by anyone not authorised by FitnEarn.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              x. At our option, replacement products could be brand-new or refurbished.
              FitnEarn reserves the full right, in our sole discretion, to fix or
              replace the Product or service.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              xi. For defective or damaged products or services, please contact us at
              the contact details below to arrange a refund or exchange.
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>CANCELLATION OF RETURN REQUEST</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              A request for return once made can be cancelled by contacting customer
              care at help-support@fitnearn.com.
            </span>
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>REFUSAL OF RETURN OR REFUND REQUEST</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              We reserve the right to refuse or cancel any return request if such a
              request is not in compliance with this Policy or applicable laws.
            </span>
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>DIGITAL CONTENT AND SUBSCRIPTIONS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              FitnEarn offers a <strong>7-day</strong> free trial for subscriptions; no
              additional trial periods or satisfaction guarantees are
              provided.&nbsp;Digital content and subscriptions on website/ app are
              non-refundable after the <strong>7-day</strong> free trial.
            </span>
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>GEOGRAPHICAL CONSIDERATIONS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              FitnEarn services are limited to India, and the policy is designed for
              operations across the country and will be followed in line with the
              applicable laws of Govt. of India.
            </span>
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>ADDITIONAL TERMS</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              These terms ("Return Policy") are in addition to the FitnEarn.com Terms
              and Conditions &amp; Privacy Policy to which you agree to by using
              FitnEarn website or mobile app or both.. In the event of any conflict
              between FitnEarn Terms and Conditions and these Refund and Return Policy
              Terms, FitnEarn will reserve the right to choose what condition will
              prevail.
            </span>
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <strong>POLICY UPDATES AND COMMUNICATION</strong>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              We will communicate policy updates via email from info@fitnearn.com. Also,
              other major policy updates will be specified on the Play Store or App
              Store during app updates. Policies will be reviewed and updated regularly.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              Please note that this Policy may subject to changes, and it is your
              responsibility to review it periodically. By using FitnEarn's services,
              you agree to comply with the terms and conditions of this Policy.
            </span>
            <br />
            &nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              <i>
                <strong>
                  Thank you for choosing FitnEarn to achieve your fitness and wellness
                  goals!
                </strong>
              </i>
            </span>
            <br />
            <br />
            &nbsp;
          </p>
          <p>
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "large"
              }}
            >
              <span lang="en-US" dir="ltr">
                <strong>QUESTIONS</strong>
              </span>
            </span>
            <br />
            <span
              style={{
                color: "#000000",
                fontFamily: "Roboto, serif",
                fontSize: "medium"
              }}
            >
              <span lang="en-US" dir="ltr">
                If you have any questions concerning our refund and return policy,
                please contact us at: <strong>Mobile :</strong> +91-8630222654{" "}
                <strong>Email:</strong>&nbsp;help-support@fitnearn.com
              </span>
            </span>
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <p>
            <br />
            &nbsp;
          </p>
        </>

      </div>
    </div>
  )
}

export default ReturnAndRefundPolicy;