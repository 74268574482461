import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <div
        style={{ padding: "60px 100px", background: "#f5f5f5", color: "black" }}
      >
        <div className="privacy-policy" style={{ padding: "80px", borderRadius: "4px", }}>
          <div>
            <div style={{ clear: "both" }}>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "center",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.36in",
                  marginBottom: "0.04in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>PRIVACY POLICY</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.36in",
                  marginBottom: "0.04in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Effective Date: 04.02.24</strong>
                </span>
              </p>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>INTRODUCTION</span>
              </h1>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  Welcome to Fitnearn. This Privacy Policy is designed to help you
                  understand how we collect, use, and safeguard your personal information
                  while using our app, and any associated websites, mobile applications,
                  and services (collectively called the "Service").
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  This Policy details the critical aspects governing your personal data
                  relationship with ‘FitnEarn’ which is owned and operated by&nbsp;
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Fit Earn Meditate</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  &nbsp;having its registered office at&nbsp;
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    395, Purav Deen Dayal, Old Railway Road, Veer Bhawan Nagar,
                    Roorkee-247667
                  </strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>.</span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  This policy document serves as an electronic record in adherence with
                  the Digital Personal Data Protection Act and outlays the various
                  agreements mandatory for access or usage of the services through the
                  FitnEarn Mobile Application&nbsp;
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <em>(hereinafter referred to as “Mobile Application”)</em>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;and Website – [</span>
                <a href="http://www.fitnearn.com/">
                  <span style={{ color: "rgb(0, 0, 255)" }}>
                    <u>www.fitnearn.com</u>
                  </span>
                </a>
                <span style={{ color: "rgb(0, 0, 0)" }}>]&nbsp;</span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <em>(hereinafter referred to as "Website")</em>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  &nbsp;and our related Website, Application, Services, Products, Devices
                  and content (together with the Mobile Application and Website,
                  collectively referred to as “Services”).
                </span>
              </p>
              <h2
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 24,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.25in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>AGREEMENT TO TERMS</span>
              </h2>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <u>User Agreement:</u>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  &nbsp;These Terms and Conditions constitute a legally binding agreement
                  made between you, whether personally or on behalf of an entity (“you” or
                  “user” or “client”) and FitnEarn (Fit Earn Meditate), concerning your
                  access to and use of the Website and FitnEarn Mobile Application as well
                  as any other media form, media channel, mobile website or mobile
                  application related, linked, or otherwise connected thereto
                  (collectively, the "Website and Mobile Application”). You agree that by
                  accessing the Website and/or Mobile Application, you have read,
                  understood, and agree to be bound by all of these Terms and Conditions.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND CONDITIONS, THEN YOU
                    ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND MUST DISCONTINUE
                    USE IMMEDIATELY.
                  </strong>
                </span>
              </p>
              <ol>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.21in",
                      marginBottom: "0in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Your personal data relationship with FitnEarn varies based on the
                      capacity in which you interact with us/avail of our products and
                      services (“
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Services</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>”). You could be:&nbsp;</span>
                  </p>
                </li>
              </ol>
              <ol type="i">
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.21in",
                      marginBottom: "0in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>a visitor to&nbsp;</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <u>https://www.fitnearn.com&nbsp;</u>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>(“</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Website</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      ”) or any pages thereof (“
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Visitor</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>”) or;</span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.21in",
                      marginBottom: "0in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      person who has an account with FitnEarn ("
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Mobile App</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>" or "</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Website</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      ") to avail our Services (“
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Registered</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>User</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>”)</span>
                  </p>
                </li>
              </ol>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginLeft: "0.25in",
                  marginBottom: "0.17in"
                }}
              >
                <br />
                &nbsp;
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginLeft: "0.25in",
                  textIndent: "-0.25in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  2. This Policy applies to all the Services provided by us through the
                  Website, App or any other associated platform of FitnEarn
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginLeft: "0.25in",
                  textIndent: "-0.25in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  3. This Policy is a part of and should be read in conjunction with our
                  Terms of Service; and
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginLeft: "0.25in",
                  textIndent: "-0.25in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  4. This Policy will clarify the rights available to you vis-à-vis the
                  personal data you share with us.
                </span>
              </p>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>ELIGIBILITY</span>
              </h1>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  You must be at least 18 to register on the Platform. If you are below
                  the ages of 18 (“Minor”), you may use our Services only under the
                  supervision and consent of a parent or guardian. In the event a minor
                  utilises the Application/Website/Services, it is assumed that he/she has
                  obtained the consent of the legal guardian or parents and such use is
                  made available by the legal guardian or parents. FitnEarn will not be
                  responsible for any consequence that arises due to, misuse of any of our
                  Services that may occur by any person including a minor registering for
                  the Services/Products provided. By using the Products or Services you
                  warrant that all the data provided by you is accurate and complete and
                  that the Minor using the website has obtained the consent of
                  parent/legal guardian (in case of minors). The Company reserves the
                  right to terminate your account and / or refuse to provide you with
                  access to the Products or Services if it is discovered that you are
                  under the age of 18 (Eighteen) years and the consent to use the Products
                  or Services is not made by your parent/legal guardian or any information
                  provided by you is inaccurate. You acknowledge that the Company does not
                  have the responsibility to ensure that you conform to the eligibility
                  criteria mentioned above. It shall be your sole responsibility to ensure
                  that you meet the required qualification. Any persons under the age of
                  18 (Eighteen) should seek the consent of their parents/legal guardians
                  before providing any Information about themselves or their parents and
                  other family members on the Website.&nbsp;
                </span>
              </p>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>INFORMATION WE COLLECT</span>
              </h1>
              <table style={{ width: "7.0e+2pt" }} cellPadding={11}>
                <tbody>
                  <tr>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "#000000",
                          lineHeight: "115%",
                          textAlign: "center",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <u>
                            <strong>User Type</strong>
                          </u>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "#000000",
                          lineHeight: "115%",
                          textAlign: "center",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.47in",
                          textIndent: "-0.18in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <u>
                            <strong>Registered, Non-premium&nbsp;</strong>
                          </u>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "#000000",
                          lineHeight: "115%",
                          textAlign: "center",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.47in",
                          textIndent: "-0.18in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <u>
                            <strong>Registered &amp; Premium</strong>
                          </u>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "#000000",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <u>
                            <strong>User Information</strong>
                          </u>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <ol>
                        <li>
                          <p
                            style={{
                              color: "rgb(0, 0, 0)",
                              lineHeight: "115%",
                              textAlign: "justify",
                              background: "transparent",
                              fontFamily: '"Roboto Regular", serif',
                              fontSize: 16,
                              marginTop: "0.21in",
                              marginBottom: "0in"
                            }}
                          >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              We ask for your email ID and/or contact Information for
                              creating your account or registering you on our platform
                            </span>
                          </p>
                        </li>
                        <li>
                          <p
                            style={{
                              color: "rgb(0, 0, 0)",
                              lineHeight: "115%",
                              textAlign: "justify",
                              background: "transparent",
                              fontFamily: '"Roboto Regular", serif',
                              fontSize: 16,
                              marginBottom: "0in"
                            }}
                          >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              We ask for your name, age weight, gender &amp; medical
                              history to personalise your fitness journey on our app.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p
                            style={{
                              color: "rgb(0, 0, 0)",
                              lineHeight: "115%",
                              textAlign: "justify",
                              background: "transparent",
                              fontFamily: '"Roboto Regular", serif',
                              fontSize: 16,
                              marginBottom: "0in"
                            }}
                          >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Your IP &amp; Location services if you have allowed access
                            </span>
                          </p>
                        </li>
                        <li>
                          <p
                            style={{
                              color: "rgb(0, 0, 0)",
                              lineHeight: "115%",
                              textAlign: "justify",
                              marginBottom: "0.1in",
                              background: "transparent",
                              fontFamily: '"Roboto Regular", serif',
                              fontSize: 16
                            }}
                          >
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Integration with third party apps such as Google Fit,
                              Samsung Health, Apple Health to access information including
                              your heart rate, number of steps taken etc.
                            </span>
                          </p>
                        </li>
                      </ol>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.3in",
                          textIndent: "-0.25in",
                          marginTop: "0.21in",
                          marginBottom: "0.17in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          1. We ask for your email ID and /or contact Information for
                          creating your account or registering you on our platform
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.3in",
                          textIndent: "-0.25in",
                          marginTop: "0.21in",
                          marginBottom: "0.17in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          2. We ask for your name, age weight, gender &amp; medical
                          history to personalise your fitness journey on our app.
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.3in",
                          textIndent: "-0.25in",
                          marginTop: "0.21in",
                          marginBottom: "0.17in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          3. Credit card/debit card/other payment mode information to
                          facilitate payments for our Services
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.3in",
                          textIndent: "-0.25in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          4. Integration with third party apps such as Google Fit, Samsung
                          Health, Apple Health to access information including your heart
                          rate, number of steps taken etc.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "#000000",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <u>
                            <strong>Purpose of Information Collection</strong>
                          </u>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                              We analyse your information to understand your behaviour and
                              improve your interactions with the website. By sharing your
                              details and consenting, you allow us to use your data for
                              emails/newsletters, targeted FitnEarn ads, and service
                              remarketing through third-party platforms like Meta and
                              Google.
                            </span>
                          </span>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span size={2} style={{ fontSize: 13 }}>
                          Additionally, we leverage this data to handle your requests,
                          inquiries, and complaints, providing customer services and
                          related activities. Additionally, we use it for communication
                          about existing or new offers, content, advertisements, surveys,
                          key policies, or other administrative information. The data also
                          serves in offering informative features and services as we
                          develop them, and for providing, testing, improving, or
                          recommending the Services.
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span size={2} style={{ fontSize: 13 }}>
                          If you provide consent, we may send you newsletters and emails
                          to promote other products and services we offer.
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontStyle: "normal" }}>
                              <span style={{ fontWeight: "normal" }}>
                                <span style={{ background: "transparent" }}>
                                  Furthermore, your health data, collected through the
                                  integration with Google Fit, is utilised to furnish
                                  personalized insights and recommendations, aiding in
                                  improving your well-being and accessing relevant
                                  health-related offerings through our app.
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                              We analyse your information to understand your behaviour and
                              improve your interactions with the website. By sharing your
                              details and consenting, you allow us to use your data for
                              emails/newsletters, targeted FitnEarn ads, and service
                              remarketing through third-party platforms like Facebook and
                              Google.
                            </span>
                          </span>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                              Additionally, we leverage this data to handle your requests,
                              inquiries, and complaints, providing customer services and
                              related activities. Additionally, we use it for
                              communication about existing or new offers, content,
                              advertisements, surveys, key policies, or other
                              administrative information. The data also serves in offering
                              informative features and services as we develop them, and
                              for providing, testing, improving, or recommending the
                              Services.
                            </span>
                          </span>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                              If you provide consent, we may send you newsletters and
                              emails to promote other products and services we offer.
                            </span>
                          </span>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                              Furthermore, your health data, collected through the
                              integration with Google Fit, is utilised to furnish
                              personalized insights and recommendations, aiding in
                              improving your well-being and accessing relevant
                              health-related offerings through our app.
                            </span>
                          </span>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                              We utilize this information to facilitate your payments for
                              our Services, employing a third-party service provider
                              solely for secure payment processing. This provider is
                              strictly prohibited from storing, retaining, or utilising
                              your data for any purpose other than payment processing on
                              our behalf.
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "#000000",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <u>
                            <strong>Device Information</strong>
                          </u>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          We collect information about the device you use to access our
                          app. We also use third parties to provide you with the best
                          experience, we ask you to link your “Goggle fit” or "Samsung
                          Health" for Android and "Google Fit" or “Apple Health” for IOS
                          with our app.
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span size={2} style={{ fontSize: 13 }}>
                            Push Notifications: We might seek permission to send push
                            notifications related to your account or the mobile
                            application. If you prefer not to receive such communications,
                            you can disable them in your device settings.
                          </span>
                        </span>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontStyle: "normal" }}>
                              <span style={{ fontWeight: "normal" }}>
                                <span style={{ background: "transparent" }}>
                                  We use your information to provide and enhance our
                                  fitness app, including delivering personalized content,
                                  improving features, and optimising user experience.
                                  Hence, we respect your data privacy and do not misuse it
                                  in anyway.
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "0.75pt solid #000000", padding: "0in 0.08in" }}>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          We collect information about the device you use to access our
                          app. We also use third parties to provide you with the best
                          experience, we ask you to link your&nbsp;
                        </span>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <span style={{ textDecoration: "none" }}>
                            <span style={{ fontStyle: "normal" }}>
                              <span style={{ fontWeight: "normal" }}>
                                <span style={{ background: "transparent" }}>
                                  “Goggle fit” or "Samsung Health" for Android and "Google
                                  Fit" or “Apple Health” for IOS with our app.
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in",
                          marginBottom: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          Push Notifications: We might seek permission to send push
                          notifications related to your account or the mobile application.
                          If you prefer not to receive such communications, you can
                          disable them in your device settings.
                        </span>
                      </p>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          lineHeight: "115%",
                          textAlign: "justify",
                          marginBottom: "0.1in",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          marginLeft: "0.05in",
                          marginTop: "0.21in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          We use your information to provide and enhance our fitness app,
                          including delivering personalized content, improving features,
                          and optimising user experience. Hence, we respect your data
                          privacy and do not misuse it in anyway.
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>HOW WE USE YOUR INFORMATION</span>
              </h1>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>A. Information Shared Voluntarily</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Account Creation</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  . When you create an account, we may collect information such as name,
                  email address, password, and log-in/display name.&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Purchases</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  . We may collect personal information and details associated with your
                  purchases, including payment information. Any payments made via our
                  Services are processed by third-party payment processors. We do not
                  directly collect or store any payment card information entered through
                  our Services, but it may receive information associated with your
                  payment card information (e.g. your billing details).&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Your Communications with Us</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  . We may collect personal information, such as name, email address,
                  phone number, mailing address, date of birth, gender, interests,
                  product, operating system, order number, or details of your request when
                  you ask for information about our Services, register for our newsletter
                  or mailing list, request customer or technical support, or otherwise
                  communicate with us.&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>App based Activity Challenges:&nbsp;</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  You may receive invitations to engage in app-based activity challenges,
                  some of which may offer rewards. Should you choose to take part, you
                  might be required to share specific information, potentially including
                  personal details. We may aggregate and present this data, in the form of
                  grouped statistics derived from users' responses, to our advertisers and
                  partners.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Interactive Features</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  . We and others who use our Services may collect personal information
                  that you submit or make available through our interactive features
                  (e.g., Community Sharing features, commenting functionalities, forums,
                  blogs, and social media pages). Any information you provide using the
                  public sharing features of the Services (referred to herein as “
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>User Content</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  ”) will be considered “public,” unless otherwise required by Applicable
                  Laws, and is not subject to the privacy protections referenced herein.
                  Please exercise caution before revealing any confidential information on
                  the public forum.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Share Content with Friends or Colleagues.&nbsp;</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  Our Services may offer various tools and functionalities. For example,
                  we may allow you to provide information about your friends through our
                  referral services. Our referral services may allow you to forward or
                  share certain content with a friend or colleague, such as an email
                  inviting your friend to use our Services. Please only share with us
                  contact information of people with whom you have a relationship (e.g.,
                  relative, friend neighbour, or co-worker).
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <br />
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>B. Information Collected Automatically&nbsp;</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We may collect personal information automatically when you use our
                  Services:
                </span>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Automatic Data Collection</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may collect device details automatically, including your mobile
                      device ID, model, manufacturer, operating system, version
                      information, and IP address.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Location Information.</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      &nbsp;We may collect your precise location information to provide
                      you with localised services.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Analytics</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may use Technologies and other third-party tools to process
                      analytics information on our Services. Some of our analytics
                      partners include:&nbsp;
                    </span>
                  </p>
                  <ul>
                    <li>
                      <p
                        style={{
                          color: "rgb(0, 0, 0)",
                          textAlign: "justify",
                          background: "transparent",
                          fontFamily: '"Roboto Regular", serif',
                          fontSize: 16,
                          lineHeight: "150%",
                          marginTop: "0.07in",
                          marginBottom: "0.07in"
                        }}
                      >
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>Google Analytics</strong>
                        </span>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          . For more information, please visit&nbsp;
                        </span>
                        <a href="http://www.google.com/policies/privacy/partners/">
                          <span style={{ color: "rgb(0, 0, 255)" }}>
                            <u>Google Analytics’ Privacy Policy</u>
                          </span>
                        </a>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          . To learn more about how to opt-out of Google Analytics’ use of
                          your information, please click&nbsp;
                        </span>
                        <a href="http://tools.google.com/dlpage/gaoptout">
                          <span style={{ color: "rgb(0, 0, 255)" }}>
                            <u>here</u>
                          </span>
                        </a>
                        <span style={{ color: "rgb(0, 0, 0)" }}>.</span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Social Media Platforms.&nbsp;</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Our Services may contain
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      social media buttons such as Facebook, Twitter, Instagram, rest and
                      YouTube (that might include widgets such as the “share this” button
                      or other interactive mini programs). These features may collect your
                      IP address, which page you are visiting on our Services, and may set
                      a cookie to enable the feature to function properly. We may also
                      participate in co-branding and other relationships to offer
                      e-commerce and other services and features to our users. Your
                      interactions with these platforms are governed by the privacy policy
                      of the company providing it.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Manage Downloads</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . The FitnEarn App is a software application installed on your
                      device, overseeing downloads, including automatic app updates and
                      security patches. While your IP address is linked to your file
                      segment, other users won't have access to any additional personal
                      information about you.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginLeft: "0.5in",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>C. Information Collected from Other Sources</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We may obtain information about you from other sources, including
                  through third-party services and organizations. For example, if you
                  access our Services through a third-party application, such as an app
                  store, a third-party login service, or a social networking site, we may
                  collect basic account information about you from that third-party
                  application or social media provider (such as your name, address, phone
                  number and e-mail address, and other demographic information, like your
                  date of birth, gender, age and interests) that you have made available
                  via your privacy settings.&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We may also use personal information and other information about you to
                  create de-identified and/or aggregated information, such as
                  de-identified demographic information, de-identified location
                  information, information about the device from which you access our
                  Services, or other reports and analyses we create. If we create or
                  receive de-identified information, we will not attempt to reidentify
                  such information, unless required to comply with Applicable Laws or
                  unless we are otherwise permitted to do so by Applicable Laws.
                </span>
              </p>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  Subject to Applicable Laws, we use personal information for a variety of
                  business purposes, including to provide our Services, for administrative
                  purposes, and to market our products and Services, as described below:
                </span>
              </h1>
              <p
                style={{
                  color: "#000000",
                  lineHeight: "150%",
                  textAlign: "left",
                  marginBottom: "0.17in",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>1. Provide Our Services</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We use personal information to fulfil our contract with you and provide
                  you with our Services, such as:
                </span>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Managing your information and accounts;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Providing access to certain areas, functionalities, and features of
                      our Services;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Answering requests for customer or technical support;&nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Communicating with you about your account, activities on our
                      Services, and policy changes;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Processing your financial information and other payment methods for
                      products or Services purchased;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Processing applications if you apply for a job we post on our
                      Services; and
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Allowing you to register for events.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>2. Administrative Purposes</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We use personal information for various administrative purposes, such
                  as:
                </span>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Pursuing our legitimate interests such as direct marketing, research
                      and development (including marketing research), network and
                      information security, and fraud prevention;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Detecting security incidents, protecting against malicious,
                      deceptive, fraudulent or illegal activity, and prosecuting those
                      responsible for that activity;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Measuring interest and engagement in our Services;&nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Short-term, transient use, such as contextual customization of ads;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Improving, upgrading or enhancing our Services;&nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Developing new products and Services;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Ensuring internal quality control and safety;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Authenticating and verifying individual identities, including
                      requests to exercise your rights under this policy;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Debugging to identify and repair errors with our Services;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Auditing relating to interactions, transactions and other compliance
                      activities;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Sharing information with third parties as needed to provide the
                      Services;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Enforcing our agreements and policies;&nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Complying with our legal obligations; and
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      To provide anonymous reporting for internal and external clients.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>3. Marketing and Advertising our Products and Services</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We may use personal information to tailor and provide you with content
                  and advertisements. We may provide you with these materials as permitted
                  by Applicable Laws.&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  Some of the ways we market to you may include email campaigns, custom
                  audiences advertising, and “interest-based” or “personalized
                  advertising,” including through cross-device tracking.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  If you have any questions about our marketing practices or if you would
                  like to opt out of the use of your personal information for marketing
                  purposes, you may contact us at any time as in “
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <span style={{ textDecoration: "none" }}>
                    <u>
                      <strong>Contact Us</strong>
                    </u>
                  </span>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>” below.&nbsp;</span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>4. Other Purposes</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We also use your information for other purposes as requested by you or
                  as permitted by Applicable Laws.
                </span>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Consent</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>.</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      We may use personal information for other purposes that are clearly
                      disclosed to you at the time you provide personal information or
                      with your consent. As a business entity, we do not intentionally
                      gather, retain, or utilize any personal data from children.
                      Individuals under the age of 18 (or any other minimum age stipulated
                      by the law of the relevant territory) should obtain parental consent
                      before sharing any information about themselves, their parents, or
                      other family members on our Services.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Automated Decision-Making</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may engage in automated decision-making, including profiling.
                      FitnEarn’s processing of your personal information will not result
                      in a decision based solely on automated processing that
                      significantly affects you unless such a decision is necessary as
                      part of a contract we have with you, we have your consent, or we are
                      permitted by law to engage in such automated decision-making. If you
                      have questions about our automated decision-making, you may contact
                      us as in “Contact Information” below.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Manage Downloads</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . The FitnEarn App is a software application installed on your
                      device, overseeing downloads, including automatic app updates and
                      security patches. While your IP address is linked to your file
                      segment, other users won't have access to any additional personal
                      information about you.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>De-identified and Aggregated Information</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may use personal information and other information about you to
                      create de-identified and/or aggregated information, such as
                      de-identified demographic information, de-identified location
                      information, information about the device from which you access our
                      Services, or other reports and analyses we create. If we create or
                      receive de-identified information, we will not attempt to reidentify
                      such information, unless required to comply with Applicable Laws or
                      unless we are otherwise permitted to do so by Applicable Laws.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Share Content with Friends or Colleagues.&nbsp;</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Our Services may offer various tools and functionalities. For
                      example, we may allow you to provide information about your friends
                      through our referral services. Our referral services may allow you
                      to forward or share certain content with a friend or colleague, such
                      as an email inviting your friend to use our Services. Please only
                      share with us contact information of people with whom you have a
                      relationship (e.g., relative, friend neighbour, or co-worker).
                    </span>
                  </p>
                </li>
              </ul>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  HOW WE DISCLOSE PERSONAL INFORMATION
                </span>
              </h1>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We disclose information solely with your explicit consent, in adherence
                  to applicable Indian laws, to deliver services, safeguard your rights,
                  or fulfil our business obligations.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Disclosures to Provide our Services</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  The categories of third parties with whom we may share your information
                  are described below.&nbsp;
                </span>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Service Providers</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may share your personal information with our third-party
                      service providers who use that information to help us provide our
                      Services. This includes service providers that provide us with IT
                      support, hosting, payment processing, customer service, and related
                      services.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Business Partners</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may share your personal information with business partners to
                      provide you with a product or service you have requested. We may
                      also share your personal information to business partners with whom
                      we jointly offer products or services.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Affiliates</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may share your personal information with our company
                      affiliates.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Other Users/Website Visitors.</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      &nbsp;As described above in “
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Person Information we collect</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      ,” our Services allow you to share your profile and/or User Content
                      with other Users/publicly, including to those who do not use our
                      Services.&nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "120%",
                      marginBottom: "0.17in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Advertising Partners.&nbsp;</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <span lang="en-US">
                        <span style={{ fontWeight: "normal" }}>
                          We do not share personal data, including health-related
                          information, with any third-party advertising partners. FitnEarn
                          strictly adheres to the principle that user data, especially
                          sensitive health data, is utilized solely for the purpose of
                          providing and enhancing the application's functionality and user
                          experience. We do not engage in the transfer of data to
                          advertising platforms, data brokers, or information resellers
                          for any purpose, including targeted advertising or other
                          commercial activities. Our commitment lies in safeguarding the
                          privacy and confidentiality of user information, ensuring that
                          it is used exclusively to fulfill the intended functionalities
                          of the FitnEarn application.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "120%",
                  marginBottom: "0.17in"
                }}
              >
                <br />
                &nbsp;
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>APIs/SDKs</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may use third-party Application Program Interfaces (“APIs”) and
                      Software Development Kits (“SDKs”) as part of the functionality of
                      our Services. For more information about our use of APIs and SDKs,
                      please contact us as set forth below.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Disclosures to Protect Us or Others</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We may access, preserve, and disclose any information we store
                  associated with you to external parties if we, in good faith, believe
                  doing so is required or appropriate to: comply with law enforcement or
                  national security requests and legal process, such as a court order or
                  subpoena; protect your, our, or others’ rights, property, or safety;
                  enforce our policies or contracts; collect amounts owed to us; or assist
                  with an investigation or prosecution of suspected or actual illegal
                  activity.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    Disclosure in the Event of Merger, Sale, or Other Asset Transfers
                  </strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  If we are involved in a merger, acquisition, financing due diligence,
                  reorganisation, bankruptcy, receivership, purchase or sale of assets, or
                  transition of service to another provider, your information may be sold
                  or transferred as part of such a transaction, as permitted by law and/or
                  contract.
                </span>
              </p>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  YOUR PRIVACY CHOICES AND RIGHTS
                </span>
              </h1>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.07in",
                  marginBottom: "0.07in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Your Privacy Choices</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  . The privacy choices you may have about your personal information are
                  determined by Applicable Laws and are described below.&nbsp;
                </span>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Email Communications</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . If you receive an unwanted email from us, you can use the
                      unsubscribe link found at the bottom of the email to opt out of
                      receiving future emails. Note that you will continue to receive
                      transaction-related emails. We may also send you certain
                      non-promotional communications regarding us and our Services, and
                      you will not be able to opt out of those communications (e.g.,
                      communications regarding our Services or updates to our Terms or
                      this Privacy Policy).&nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Text Messages.&nbsp;</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      You may opt out of receiving text messages from us by following the
                      instructions in the text message you have received from us or by
                      otherwise contacting us.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Mobile Devices</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . We may send you push notifications through our mobile application.
                      You may opt out from receiving these push notifications by changing
                      the settings on your mobile device. With your consent, we may also
                      collect precise location-based information via our mobile
                      application. You may opt out of this collection by changing the
                      settings on your mobile device.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.07in",
                      marginBottom: "0.07in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Cookies and Personalized Advertising</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . You may stop or restrict the placement of Technologies on your
                      device or remove them by adjusting your preferences as your browser
                      or device permits. However, if you adjust your preferences, our
                      services may not work properly.&nbsp;
                    </span>
                  </p>
                </li>
              </ul>
              <h1
                style={{
                  color: "rgb(13, 13, 13)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 32,
                  fontWeight: "bold",
                  lineHeight: "150%",
                  marginTop: "0.28in",
                  marginBottom: "0.08in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>DATA PROTECTION</span>
              </h1>
              <ul>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.21in",
                      marginBottom: "0.21in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      All the information shared or access granted by the users is used
                      explicitly for application purposes to run smoothly on your device,
                      it helps us make it more user-friendly and compatible with your
                      device. All the information provided by a user (E.g:- medical
                      history, height, weight, age etc) is strictly encrypted and private.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginTop: "0.21in",
                      marginBottom: "0.21in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      We implement diligent security practices and procedures to protect
                      your personal information within our control from unauthorized
                      access. It's important to recognize that no Internet transmission,
                      system, or server can be guaranteed&nbsp;
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <u>100% secure</u>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . Despite our commitment to taking all reasonable steps to secure
                      your personal information, we do not assure or guarantee absolute
                      security. Therefore, it is advisable not to expect that your
                      personal information or other communications while utilising the
                      Platform will always be impervious to breaches. Caution should be
                      exercised when providing, sharing, or disclosing your personal
                      information on the Platform.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginRight: "0.28in",
                      marginTop: "0.17in",
                      marginBottom: "0.17in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Additionally, Fitnearn has all the copyrights to its content and
                      will not appreciate data theft or illegal uploading of our content
                      in any circumstances.
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      textAlign: "justify",
                      background: "transparent",
                      fontFamily: '"Roboto Regular", serif',
                      fontSize: 16,
                      lineHeight: "150%",
                      marginRight: "0.28in",
                      marginTop: "0.17in",
                      marginBottom: "0.17in"
                    }}
                  >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      Policies for data breaches include the&nbsp;
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        Digital Personal Data Protection (DPDP) Act, of 2023
                      </strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      . This is the first cross-sectoral law on personal data protection
                      in India and has been enacted after more than half a decade of
                      deliberations. And the&nbsp;
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>Content Security Policy (CSP)&nbsp;</strong>
                    </span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      data theft under section 43(b) read with Section 66 of the IT Act is
                      always associated with Section 379, 420 of IPC.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "120%",
                  marginBottom: "0.17in"
                }}
              >
                <strong>Data Deletion Information</strong>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "120%",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ fontWeight: "normal" }}>
                  Within our app, you have the ability to manage and delete your personal
                  information through the "Delete Account" feature located in the Platform
                  settings. It's important to note that we do not retain any personal
                  information of our users. However, certain information may be kept for
                  record-keeping purposes as required by law. This retention is essential
                  for preventing fraud, enforcing our Platform Terms and Conditions,
                  protecting the integrity of our Platform, and complying with legal
                  obligations.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "120%",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <span lang="en-US">
                    <span style={{ fontWeight: "normal" }}>
                      Users have the right to access, modify, or delete their personal
                      information stored within the FitnEarn application. To exercise
                      these rights, users can navigate to the settings section within the
                      application, where they will find options to modify, manage and
                      delete their account data. Additionally, users can contact our
                      support team at help-support@fitnearn.com for assistance with data
                      deletion requests.&nbsp;
                    </span>
                  </span>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  fontWeight: "normal",
                  lineHeight: "120%",
                  marginBottom: "0.17in"
                }}
              >
                <br />
                &nbsp;
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "120%",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <span lang="en-US">
                    <span style={{ fontWeight: "normal" }}>
                      We are committed to promptly addressing user requests regarding data
                      deletion in accordance with applicable privacy laws and regulations.
                    </span>
                  </span>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>CONTACT INFORMATION</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  All privacy-related complaints or concerns should be exclusively
                  communicated through email to our designated address at&nbsp;
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>help-support@fitnearn.com</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>&nbsp;or call us at&nbsp;</span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>+91-8630222654</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  .We value your privacy and are dedicated to addressing any issues raised
                  with the utmost diligence. By centralising privacy-related
                  communications through this specified email channel, we aim to
                  streamline the resolution process and ensure that your concerns are
                  handled promptly and effectively. Thank you for your understanding and
                  cooperation as we work to maintain the highest standards in protecting
                  your privacy.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>YOUR CHOICES AND CONTROLS</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(55, 65, 81)" }}>&nbsp;</span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Account Information</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  You can review and update your account information by accessing your
                  account settings in the app. All the information will be modified as you
                  change it every time. The provided mobile number, email ID and
                  information are most important for us to register you on our platform.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Opting Out&nbsp;</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.17in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  You can opt out of receiving promotional emails by following the
                  instructions provided in the emails. You can contact us if there are any
                  issues with marketing email and promotional activities.&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>SECURITY</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  We employ industry-standard security measures to protect your
                  information from unauthorized access, disclosure, alteration, and
                  destruction. The exchange of information even from a third party is
                  secure and is shared with us with your consent.&nbsp;
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>CHANGES TO THIS PRIVACY POLICY</strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginTop: "0.21in",
                  marginBottom: "0.21in"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>Fitnearn&nbsp;</strong>
                </span>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  reserves the right to update this Privacy Policy at any time. Any
                  changes will be effective immediately, informed and marketed. The user
                  must obey all our privacy policies to access our application and its
                  services.
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0.25in",
                  background: "rgb(255, 255, 255)"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span style={{ background: "#ffffff" }}>
                      BY USING THE FITNEARN APP/WEBSITE, USERS ACKNOWLEDGE THAT THEY HAVE
                      READ, UNDERSTOOD, AND AGREED TO THESE PRIVACY POLICIES. FROM TIME TO
                      TIME FITEARN CAN MAKE REQUIRED CHANGES IN THE POLICIES AND USERS
                      WILL GET UPDATED ON IT.&nbsp;
                    </span>
                  </strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0.25in",
                  background: "rgb(255, 255, 255)"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span style={{ background: "#ffffff" }}>Last updated on&nbsp;</span>
                  </strong>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0.25in",
                  background: "rgb(255, 255, 255)"
                }}
              >
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <u>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "#ffffff" }}>4th Feb 2024</span>
                    </span>
                  </u>
                </span>
              </p>
              <p
                style={{
                  color: "rgb(0, 0, 0)",
                  textAlign: "justify",
                  background: "transparent",
                  fontFamily: '"Roboto Regular", serif',
                  fontSize: 16,
                  lineHeight: "150%",
                  marginBottom: "0.17in"
                }}
              >
                <br />
                &nbsp;
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;